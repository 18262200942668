const FrontpageDesktop = ({ className = "" }) => (
  <svg
    width="1440"
    height="665"
    viewBox="0 0 1440 665"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M438.65 471.648H414.427V462.74H311.888V497.872H414.427V488.838H438.65V471.648Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M322.179 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M328.455 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M335.357 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M360.71 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M369.496 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M379.536 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M385.184 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M390.204 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M398.111 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M352.176 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M339.876 469.766V489.089"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M328.581 538.023L305.487 530.871L308.123 522.339L210.102 492.226L199.81 525.726L297.831 555.84L300.467 547.308L323.56 554.459L328.581 538.023Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M217.883 502.012L212.235 520.457"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M223.908 503.769L218.26 522.339"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M254.657 513.305L249.009 531.749"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M263.066 515.814L257.418 534.384"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M272.73 518.826L267.082 537.27"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M290.552 524.346L284.778 542.791"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M246.499 510.795L240.851 529.24"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M234.701 507.157L229.053 525.601"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M385.184 582.189C394.095 570.143 405.014 552.703 413.172 531.247C419.322 515.187 421.456 500.381 422.083 488.461"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M434.007 488.461C433.505 501.51 431.12 517.822 424.343 535.513C414.804 560.482 400.622 582.189 390.832 594.861"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M422.585 470.393C422.836 466.504 423.213 463.241 423.589 460.858V460.607C424.719 451.071 428.986 436.391 435.889 417.821L448.44 417.946C443.671 429.992 436.893 450.82 435.513 461.987V462.238C435.262 464.371 434.885 467.257 434.634 470.644"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M347.155 498.499C342.135 508.788 336.111 518.575 329.71 526.228C327.2 529.24 325.192 532.376 323.56 535.639"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M346.528 462.74C347.407 457.219 347.407 452.451 346.402 448.938C342.386 435.01 332.973 429.992 332.597 429.741L337.994 419.076C338.621 419.326 352.301 426.478 357.949 445.55C359.33 450.067 359.455 455.588 358.828 461.61"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M312.014 531.874C314.147 527.358 316.908 522.841 320.548 518.574C325.568 512.677 330.212 505.4 334.103 497.746"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M326.07 597.371C322.807 594.234 319.544 590.721 316.657 586.706C309.88 577.17 305.613 563.744 307.119 549.566"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M318.917 552.326C318.791 553.456 318.791 554.585 318.791 555.714C318.917 564.497 321.678 573.28 326.321 579.805C328.33 582.691 330.589 585.2 332.973 587.584"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M411.792 629.993L369.496 556.718C365.229 549.315 354.937 549.19 350.67 556.592L308.499 629.617C304.107 637.145 308.75 646.179 317.661 646.179H402.379C411.039 646.179 416.059 637.271 411.792 629.993Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M365.605 611.674H354.561V574.786H365.605V611.674ZM365.605 631.374H354.686V621.336H365.605V631.374Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M571.437 576.919H441.662V642.415H571.437V576.919Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M539.934 629.617C551.233 629.617 560.392 620.46 560.392 609.165C560.392 597.87 551.233 588.713 539.934 588.713C528.636 588.713 519.477 597.87 519.477 609.165C519.477 620.46 528.636 629.617 539.934 629.617Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M549.598 591.474L539.934 609.165H560.392"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M455.092 584.824H449.067V634.385H455.092V584.824Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M467.893 602.641H461.995V634.385H467.893V602.641Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M480.695 592.226H474.796V634.51H480.695V592.226Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M491.489 588.839H510.817"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M491.489 594.485H505.922"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M491.489 600.131H510.817"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M491.489 618.575H505.42"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M491.489 624.598H505.043"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M491.489 630.621H510.817"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M497.513 605.777H491.489V611.8H497.513V605.777Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M508.307 605.777H502.282V611.8H508.307V605.777Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M244.742 653.959L233.823 643.043C231.94 641.16 231.94 638.149 233.823 636.267L273.608 596.492C275.491 594.61 278.503 594.61 280.386 596.492L291.305 607.409C293.188 609.291 293.188 612.302 291.305 614.184L251.519 653.959C249.636 655.841 246.624 655.841 244.742 653.959Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256.163 639.655C256.163 644.046 252.523 647.685 248.131 647.685C243.738 647.685 240.098 644.046 240.098 639.655C240.098 635.263 243.738 631.625 248.131 631.625C252.649 631.625 256.163 635.138 256.163 639.655Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M244.993 638.024L246.499 636.392"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M249.762 642.792L251.394 641.286"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M270.722 625.1C270.722 629.492 267.082 633.13 262.689 633.13C258.296 633.13 254.657 629.492 254.657 625.1C254.657 620.709 258.296 617.07 262.689 617.07C267.082 617.07 270.722 620.709 270.722 625.1Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M259.426 623.469L261.058 621.963"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M264.195 628.362L265.827 626.731"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M285.155 610.671C285.155 615.062 281.516 618.701 277.123 618.701C272.73 618.701 269.09 615.062 269.09 610.671C269.09 606.279 272.73 602.641 277.123 602.641C281.641 602.641 285.155 606.154 285.155 610.671Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M273.859 609.04L275.491 607.409"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M278.754 613.933L280.385 612.302"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M285.155 601.01L286.786 599.378C291.054 595.112 298.71 595.238 302.851 599.378C306.993 603.644 307.119 609.667 302.851 613.808L293.187 623.469C289.548 627.108 289.548 632.628 293.187 636.393C296.827 640.031 302.349 640.031 306.114 636.393"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M540.562 375.537C541.44 377.042 541.943 378.674 541.943 380.556C541.943 382.438 541.44 384.069 540.562 385.575C538.805 388.586 535.667 390.593 531.902 390.593C528.137 390.593 524.999 388.586 523.242 385.575C522.363 384.069 521.861 382.438 521.861 380.556C521.861 378.674 522.363 377.042 523.242 375.537C524.999 372.525 528.137 370.518 531.902 370.518C535.667 370.518 538.805 372.525 540.562 375.537Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M531.902 383.818C530.522 383.818 529.518 382.94 529.016 382.187C528.639 381.685 528.514 381.058 528.514 380.556C528.514 379.928 528.639 379.426 528.89 378.925C529.392 378.046 530.396 377.168 531.777 377.168C533.157 377.168 534.161 378.046 534.663 378.799C535.04 379.301 535.165 379.928 535.165 380.43C535.165 381.058 535.04 381.559 534.789 382.061C534.287 382.94 533.283 383.818 531.902 383.818Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M573.947 375.537C574.825 377.042 575.327 378.674 575.327 380.556C575.327 382.438 574.825 384.069 573.947 385.575C572.19 388.586 569.052 390.593 565.287 390.593C561.521 390.593 558.384 388.586 556.627 385.575C555.748 384.069 555.246 382.438 555.246 380.556C555.246 378.674 555.748 377.042 556.627 375.537C558.384 372.525 561.521 370.518 565.287 370.518C569.052 370.518 572.19 372.525 573.947 375.537Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M565.287 383.818C563.907 383.818 562.903 382.94 562.401 382.187C562.024 381.685 561.898 381.058 561.898 380.556C561.898 379.928 562.024 379.426 562.275 378.925C562.777 378.046 563.781 377.168 565.162 377.168C566.542 377.168 567.546 378.046 568.048 378.799C568.425 379.301 568.55 379.928 568.55 380.43C568.55 381.058 568.425 381.559 568.174 382.061C567.672 382.94 566.668 383.818 565.287 383.818Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M670.587 375.537C671.466 377.042 671.968 378.674 671.968 380.556C671.968 382.438 671.466 384.069 670.587 385.575C668.83 388.586 665.692 390.593 661.927 390.593C658.288 390.593 655.024 388.586 653.267 385.575C652.389 384.069 651.887 382.438 651.887 380.556C651.887 378.674 652.389 377.042 653.267 375.537C655.024 372.525 658.162 370.518 661.927 370.518C665.692 370.518 668.956 372.525 670.587 375.537Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M661.928 383.818C660.547 383.818 659.543 382.94 659.041 382.187C658.665 381.685 658.539 381.058 658.539 380.556C658.539 379.928 658.665 379.426 658.916 378.925C659.418 378.046 660.422 377.168 661.802 377.168C663.183 377.168 664.187 378.046 664.689 378.799C665.065 379.301 665.191 379.928 665.191 380.43C665.191 381.058 665.065 381.559 664.814 382.061C664.438 382.94 663.434 383.818 661.928 383.818Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M627.539 546.178V641.537C627.539 642.666 626.535 643.67 625.405 643.67H605.826C604.696 643.67 603.692 642.666 603.692 641.537V545.802"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M603.692 409.414V346.553C603.692 345.424 604.696 344.42 605.826 344.42H625.405C626.535 344.42 627.539 345.424 627.539 346.553V409.665"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M611.348 462.489L605.449 481.309H614.737L610.846 492.978H615.364L626.032 473.279H615.364L619.004 462.238L611.348 462.489Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M645.988 545.551H585.619C584.113 545.551 582.858 544.296 582.858 542.791V412.551C582.858 411.045 584.113 409.791 585.619 409.791H645.988C647.494 409.791 648.749 411.045 648.749 412.551V542.791C648.749 544.296 647.494 545.551 645.988 545.551Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M582.983 426.604H648.373"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M583.611 412.676C582.858 412.3 582.105 412.049 581.226 412.049H566.793C564.283 412.049 562.275 413.806 562.275 416.064V419.075C562.275 421.208 564.283 423.091 566.793 423.091H568.801C568.927 420.707 571.186 418.699 574.073 418.699C576.959 418.699 579.218 420.707 579.344 423.091H581.352C581.979 423.091 582.607 422.965 583.109 422.84"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M647.62 412.676C648.373 412.3 649.126 412.049 650.004 412.049H664.438C666.948 412.049 668.956 413.806 668.956 416.064V419.075C668.956 421.208 666.948 423.091 664.438 423.091H662.429C662.304 420.707 660.045 418.699 657.158 418.699C654.271 418.699 652.012 420.707 651.887 423.091H649.879C649.251 423.091 648.624 422.965 648.122 422.84"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M595.283 445.926C598.749 445.926 601.559 443.118 601.559 439.653C601.559 436.188 598.749 433.379 595.283 433.379C591.817 433.379 589.008 436.188 589.008 439.653C589.008 443.118 591.817 445.926 595.283 445.926Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M589.761 443.542L600.555 436.014"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M615.616 445.926C619.081 445.926 621.891 443.118 621.891 439.653C621.891 436.188 619.081 433.379 615.616 433.379C612.15 433.379 609.34 436.188 609.34 439.653C609.34 443.118 612.15 445.926 615.616 445.926Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M619.381 445.299L612.353 434.258"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M635.948 445.926C639.414 445.926 642.223 443.118 642.223 439.653C642.223 436.188 639.414 433.379 635.948 433.379C632.482 433.379 629.672 436.188 629.672 439.653C629.672 443.118 632.482 445.926 635.948 445.926Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M635.822 446.428L636.199 433.254"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M523.242 385.449H508.181V375.662H523.117"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M556.376 385.449H540.688"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M604.069 385.449H574.198"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M653.519 385.449H627.288"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M671.09 375.662H723.05V385.449H671.09"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M626.535 375.662H653.268"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M574.072 375.662H603.566"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M541.064 375.662H556.376"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M469.149 386.453V389.715C469.149 390.97 468.019 391.974 466.764 391.974L421.707 391.221C420.452 391.221 419.448 390.092 419.448 388.837V385.575C419.448 384.32 420.577 383.316 421.832 383.316L466.889 384.069C468.145 384.069 469.149 385.198 469.149 386.453Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M430.743 382.689L431.12 362.99C431.12 361.735 432.249 360.731 433.504 360.731H434.508C435.763 360.731 436.768 361.86 436.768 363.115L436.391 382.814"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M452.205 383.065L452.582 363.366C452.582 362.111 453.711 361.108 454.966 361.108H455.97C457.225 361.108 458.229 362.237 458.229 363.492L457.853 383.191"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M285.908 407.03L300.969 415.562"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M298.208 420.456L286.536 414.057"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M308.625 401.886L291.305 392.225C290.175 391.597 289.799 390.217 290.426 389.088L290.928 388.209C291.556 387.08 292.937 386.704 294.066 387.331L311.386 396.992"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M466.889 392.099L466.638 404.897C466.513 412.049 460.614 417.821 453.334 417.695L433.881 417.319C426.727 417.193 420.954 411.296 421.079 404.019L421.33 391.848"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M318.917 387.582L321.929 389.088C323.058 389.715 323.56 391.095 322.933 392.225L302.35 432.376C301.722 433.505 300.342 434.007 299.212 433.379L296.2 431.874C295.07 431.246 294.568 429.866 295.196 428.737L315.779 388.586C316.406 387.457 317.787 387.08 318.917 387.582Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M322.933 392.099L334.354 397.996C340.755 401.259 343.265 409.163 340.002 415.562L331.216 432.877C327.953 439.276 320.046 441.786 313.645 438.524L302.726 433.003"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M684.519 499.001C685.648 499.754 686.401 500.632 686.778 501.636C686.778 501.636 687.907 507.533 687.907 515.438C687.907 523.342 687.907 524.723 685.021 524.723C682.134 524.723 681.381 524.095 679.749 519.453C678.243 514.81 677.239 509.29 677.239 509.29"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M662.179 503.643H668.329C668.329 503.643 671.717 496.241 675.859 496.366C677.616 496.492 681.758 497.119 684.519 498.876"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M668.956 517.32C668.956 517.32 677.867 517.194 678.118 513.556"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M684.77 498.625C685.272 498.123 686.025 497.746 686.778 497.746H691.924C693.556 497.746 694.811 499.001 694.811 500.632V501.761C694.811 503.392 693.556 504.647 691.924 504.647H687.531"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M688.66 522.715H689.539C690.919 522.715 692.049 523.844 692.049 525.225V527.232C692.049 528.612 690.919 529.742 689.539 529.742H686.527C685.146 529.742 684.017 528.612 684.017 527.232V525.225"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M687.908 506.78C688.284 506.655 688.661 506.529 689.037 506.529H694.183C695.815 506.529 697.07 507.784 697.07 509.415V510.544C697.07 512.175 695.815 513.43 694.183 513.43H689.037C688.661 513.43 688.284 513.305 687.908 513.179"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M688.033 514.936H692.426C694.057 514.936 695.313 516.191 695.313 517.822V518.951C695.313 520.582 694.057 521.837 692.426 521.837H688.033"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M663.057 527.107H667.826C667.826 527.107 669.458 529.24 671.215 530.243C671.341 530.243 671.341 530.369 671.466 530.369"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M661.551 499.879H649.377V532.753H661.551V499.879Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M684.268 493.104L687.656 472.15L690.92 469.766L689.916 458.097L685.648 457.47L681.13 468.135L683.389 471.397L680 492.476"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M683.892 529.491C683.39 530.369 682.636 531.122 681.758 531.749C680.126 532.878 678.118 533.38 676.11 533.004C672.47 532.376 669.835 528.863 670.462 525.224L671.717 517.069"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M674.353 496.366L674.981 492.351L689.037 494.609L688.535 497.621"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M657.324 609.861L802.285 609.861L802.285 597.314L657.324 597.314L657.324 609.861Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M795.969 610.294V634.887"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M663.685 610.294V634.887"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M767.228 506.404C779.428 506.404 789.317 496.517 789.317 484.321C789.317 472.125 779.428 462.238 767.228 462.238C755.028 462.238 745.139 472.125 745.139 484.321C745.139 496.517 755.028 506.404 767.228 506.404Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M767.228 471.648V484.321H754.552"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M682.636 597.245H720.414V584.698H682.636C679.122 584.698 676.361 587.458 676.361 590.972C676.235 594.485 679.122 597.245 682.636 597.245Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M676.235 584.698H714.013V572.151H676.235C672.721 572.151 669.96 574.911 669.96 578.425C669.96 581.812 672.847 584.698 676.235 584.698Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M714.139 572.025H676.361V559.478H714.139C717.653 559.478 720.414 562.239 720.414 565.752C720.414 569.265 717.527 572.025 714.139 572.025Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M765.471 558.475V542.665C765.471 535.764 759.823 530.118 752.92 530.118"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M774.884 539.528C781.815 539.528 787.435 536.72 787.435 533.255C787.435 529.79 781.815 526.981 774.884 526.981C767.952 526.981 762.333 529.79 762.333 533.255C762.333 536.72 767.952 539.528 774.884 539.528Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M752.795 596.242C744.135 596.242 736.981 589.215 736.981 580.432V561.486C736.981 554.585 742.629 548.939 749.532 548.939H752.669C759.572 548.939 765.22 554.585 765.22 561.486C765.22 554.585 770.868 548.939 777.771 548.939H780.909C787.811 548.939 793.459 554.585 793.459 561.486V580.432C793.459 589.09 786.431 596.242 777.645 596.242H752.795Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M505.42 487.332L498.643 508.286H509.185L504.792 521.46H509.813L521.736 499.252H509.813L513.829 487.081L505.42 487.332Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M510.189 532.376C525.716 532.376 538.303 519.793 538.303 504.271C538.303 488.748 525.716 476.165 510.189 476.165C494.663 476.165 482.076 488.748 482.076 504.271C482.076 519.793 494.663 532.376 510.189 532.376Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M564.283 505.275C560.894 498.499 555.874 494.359 548.971 493.731C548.594 492.351 548.218 491.096 547.716 489.842C552.234 488.461 559.263 481.309 557.631 478.047C551.356 473.907 544.955 472.777 538.554 475.789C537.55 474.785 536.546 473.907 535.542 473.028C538.679 469.641 541.19 459.979 538.177 457.846C530.647 457.344 524.497 459.728 520.606 465.375C519.351 464.998 517.971 464.747 516.59 464.496C517.594 459.979 514.959 450.318 511.319 450.067C504.541 453.455 500.4 458.474 499.772 465.375C498.392 465.751 497.137 466.127 495.881 466.629C494.501 462.112 487.347 455.211 484.084 456.717C479.942 462.991 478.812 469.39 481.825 475.789C480.821 476.793 479.942 477.796 479.063 478.8C475.675 475.663 466.011 473.154 463.877 476.165C463.375 483.693 465.76 489.842 471.408 493.731C471.031 494.986 470.78 496.366 470.529 497.746C466.011 496.743 456.347 499.377 456.096 503.016C459.484 509.792 464.505 513.932 471.408 514.559C471.784 515.94 472.161 517.194 472.663 518.449C468.144 519.829 461.116 526.981 462.748 530.243C469.023 534.384 475.424 535.513 481.699 532.502C482.703 533.506 483.707 534.384 484.711 535.262C481.574 538.65 479.064 548.311 482.076 550.444C489.606 550.946 495.756 548.562 499.647 542.916C500.902 543.292 502.282 543.543 503.663 543.794C502.659 548.311 505.295 557.973 508.934 558.224C515.712 554.836 519.853 549.817 520.481 542.916C521.861 542.54 523.117 542.163 524.372 541.661C525.752 546.178 532.906 553.205 536.169 551.574C540.311 545.3 541.441 538.901 538.428 532.502C539.433 531.498 540.311 530.494 541.19 529.491C544.578 532.627 554.242 535.262 556.376 532.126C556.878 524.597 554.493 518.449 548.846 514.559C549.222 513.305 549.473 511.925 549.724 510.544C554.368 511.674 564.032 508.913 564.283 505.275Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M934.529 633.13L926.999 632.377C926.246 632.252 925.744 631.75 925.744 631.123C925.744 630.495 926.497 629.993 927.25 629.993L934.027 630.37L934.153 628.864L924.614 627.86C923.861 627.735 923.233 627.108 923.359 626.355C923.359 625.602 924.112 625.1 924.865 625.1L934.529 625.727L934.655 624.222L923.359 623.093C922.48 622.967 921.853 622.34 921.978 621.587C921.978 620.834 922.731 620.332 923.61 620.332L934.906 621.085L935.031 619.579L925.618 618.576C924.74 618.45 924.112 617.823 924.238 617.07C924.238 616.317 924.991 615.815 925.869 615.815L939.047 616.944C939.173 616.944 939.173 616.944 939.298 616.944C940.553 616.944 939.8 615.69 939.8 615.69C939.8 615.69 937.541 615.188 934.906 614.184C932.144 613.306 933.274 611.549 933.274 611.549L938.796 612.051L943.942 613.306L950.218 618.074C950.845 618.074 951.222 618.199 952.351 618.199C953.481 618.199 954.736 617.948 955.991 617.572C957.873 617.07 963.396 613.808 964.149 614.811C969.295 621.712 960.76 633.632 957.246 633.632C955.112 633.632 955.74 633.381 954.359 633.005C953.23 632.628 952.226 632.127 951.096 632.001C950.092 631.876 949.841 631.876 949.088 631.876L942.687 633.381L934.529 633.13Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M947.582 571.147C954.61 571.775 963.521 575.037 967.789 580.056C972.683 585.953 974.817 588.964 974.817 588.964C974.817 588.964 983.351 606.907 983.351 610.42C983.351 613.933 986.866 629.492 982.222 634.259C977.453 639.027 959.756 633.507 959.756 633.507"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M924.489 546.68L922.732 547.433C922.104 550.444 922.104 553.581 922.606 556.718L923.987 557.847L924.112 558.976C924.489 563.619 927.878 570.269 932.27 573.155C934.404 573.908 936.789 574.409 939.048 574.409C944.193 573.406 949.841 568.512 951.975 564.372L952.477 563.368L954.108 562.866C955.74 560.106 956.995 557.345 957.497 554.209L956.242 552.828"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M954.108 519.955C959.129 523.97 961.764 530.871 960.384 538.023L959.003 545.049C958.878 546.053 957.874 546.555 956.744 546.429L926.371 540.532C925.367 540.281 924.614 539.403 924.865 538.399L926.246 531.373C927.626 524.221 932.647 518.826 938.922 517.069"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M949.339 524.346L948.586 523.342L950.092 515.563C950.218 514.559 949.59 513.681 948.586 513.43L946.703 513.054C945.699 512.803 944.695 513.43 944.444 514.434L942.938 522.213L941.809 522.841"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M936.287 530.745L938.797 517.947L939.048 516.693C939.173 516.316 939.424 516.065 939.801 516.065L944.193 515.312"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M949.967 516.441L953.857 518.7C954.234 518.951 954.359 519.327 954.359 519.578L954.108 520.833L951.598 533.631"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M962.392 548.437L962.517 548.06L963.396 543.292C963.522 542.289 962.894 541.41 961.89 541.159L960.007 540.783"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M920.598 540.281V539.905L921.477 535.137C921.602 534.133 922.606 533.631 923.736 533.757L925.618 534.133"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M920.723 539.779C920.723 539.905 920.723 540.03 920.723 540.156C920.347 545.049 929.007 548.939 940.177 551.072C951.347 553.205 960.886 552.954 962.392 548.186C962.392 548.06 962.517 547.935 962.517 547.81"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M949.716 633.632L944.821 644.172C944.194 645.427 942.813 646.43 941.558 646.43H905.788C904.533 646.43 904.031 645.427 904.533 644.172L923.861 602.766C924.489 601.511 925.869 600.508 927.125 600.508H929.635"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M958.375 600.508H962.894C964.149 600.508 964.651 601.511 964.149 602.766L958.375 615.062"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M953.23 597.747H949.841C950.971 595.238 949.966 593.23 947.456 593.23C944.946 593.23 942.06 595.238 940.93 597.747H937.667C935.784 597.747 933.651 599.253 932.772 601.135H955.112C955.991 599.253 955.112 597.747 953.23 597.747Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M928.505 570.018C928.505 570.018 922.606 567.634 915.452 568.889C915.954 568.763 907.796 570.52 905.914 572.402"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M867.885 547.559C867.634 547.935 867.508 548.186 867.257 548.562C864.371 552.954 861.484 555.589 861.484 555.589C861.484 555.589 868.136 566.379 876.419 568.638C881.188 570.018 885.079 568.261 887.464 566.379"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M869.517 540.281C868.512 543.167 867.885 545.3 867.759 545.927C867.508 546.806 868.512 549.566 870.27 549.692C870.395 549.692 871.148 549.943 871.274 549.817"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M872.528 541.536C872.654 541.787 872.654 541.912 872.779 542.163C872.779 542.289 872.779 542.289 872.779 542.414C872.654 542.414 872.403 542.414 872.277 542.54C869.642 543.418 869.391 546.931 871.022 549.566C872.654 552.076 874.035 552.577 875.29 552.828C875.415 553.707 875.541 554.209 875.541 554.209C875.792 555.338 876.67 557.345 877.674 558.976C878.804 560.608 884.201 563.87 885.958 565.626C887.715 567.383 889.472 568.261 891.982 567.76C894.618 567.132 897.63 562.615 898.257 561.988C898.759 561.36 900.517 561.36 901.019 560.482C901.144 560.231 901.144 559.98 901.144 559.729"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M850.314 619.454C851.193 623.845 853.954 635.012 856.338 638.526C859.225 642.792 863.869 644.297 870.897 644.799C877.925 645.176 893.488 644.925 898.76 645.176C900.391 645.301 901.772 645.176 903.027 645.176"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M910.181 632.126C909.302 632.754 908.549 633.256 908.047 633.758C906.165 635.389 901.772 635.263 896.249 635.012C890.853 634.761 880.436 632.628 876.294 632.126C872.152 631.75 870.018 631.248 869.767 630.621C869.642 630.244 868.512 624.724 867.257 618.575"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M884.326 546.931L898.885 558.475C900.266 559.604 902.148 559.855 903.905 559.102L914.448 554.585C916.205 553.832 916.582 551.448 915.201 550.193L907.169 542.916"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M869.014 540.03C869.014 540.03 881.188 545.676 886.334 547.809C891.354 549.943 905.16 545.927 907.043 542.791C907.043 542.791 906.917 531.749 904.031 527.232C900.768 522.213 897.128 516.943 887.715 516.943C878.302 516.943 873.281 521.209 871.148 528.11C869.516 533.129 869.014 540.03 869.014 540.03Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M898.76 561.611C898.76 561.611 905.161 572.277 906.165 575.037C907.169 577.797 911.059 585.827 911.938 592.603C913.068 602.139 916.958 609.793 916.707 614.059"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M867.885 618.074C869.391 617.195 870.018 616.442 870.018 616.442C870.018 616.442 863.743 604.899 863.994 600.633C864.245 596.367 862.99 583.067 861.735 579.93"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M866.881 548.813C866.881 548.813 857.719 553.205 855.585 555.463C853.451 557.722 843.662 563.87 842.783 565.125C841.905 566.379 837.889 570.394 837.638 574.786C837.387 579.177 836.633 589.215 840.775 596.618C844.917 604.021 846.674 611.675 847.051 614.435C847.553 617.195 848.557 618.952 848.557 618.952C848.557 618.952 850.188 619.454 853.828 619.956C857.593 620.458 866.379 618.952 867.885 618.074"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M921.351 543.794C921.351 543.794 918.966 544.924 918.966 546.178C918.966 547.433 917.837 548.562 917.837 549.692C917.837 550.821 918.966 553.205 917.837 554.459C916.707 555.714 914.323 556.843 914.323 557.973C914.323 559.102 913.193 559.102 913.193 560.357C913.193 561.611 910.808 561.486 910.808 562.741C910.808 563.995 909.679 563.87 910.808 565.125C911.938 566.379 909.679 569.892 909.679 569.892"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M962.643 546.806C962.643 546.806 965.404 548.562 965.404 549.817C965.404 551.072 963.019 552.201 964.274 553.33C965.53 554.459 967.789 556.843 965.404 559.227C964.525 560.106 965.404 565.125 963.019 565.125C960.635 565.125 960.635 574.66 960.635 574.66"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 437.018V404.897"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.28 495.613L223.907 454.96"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M249.26 452.2L248.758 503.769"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M249.26 297.995V447.809"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M249.26 280.053V291.471"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M249.26 37.8925V259.476"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 29.7368V0.62738H249.26V29.9878"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 248.434V37.767"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 287.079V264.996"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 312.55V292.6"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 336.766V318.071"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 404.897V343.416"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M204.077 510.042L203.952 370.267"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M209.474 370.267L210.353 492.476"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M209.474 342.914V366.252"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M209.474 318.071V337.017"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M209.474 229.739V312.55"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M203.952 225.975V29.7368H209.474V225.724"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M203.952 312.675V230.868"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M203.952 337.393V318.071"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M203.952 366.252V343.416"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M222.527 455.211L220.142 439.527L212.988 433.379L215.875 430.117L224.284 437.269L226.292 450.318L252.9 447.181L253.402 451.573L222.527 455.211Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 409.163L217.255 411.171L212.109 390.719L222.275 396.742L220.016 400.506L219.012 400.004L220.518 406.026L223.907 404.646"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M249.26 400.255L260.304 399.126L261.183 403.392L249.26 404.646"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M260.807 268.384L263.066 269.513C263.066 269.513 286.285 262.863 288.418 261.734C290.552 260.605 294.819 259.35 297.831 257.844C300.09 256.715 303.103 252.324 305.613 254.582C307.37 256.213 310.759 263.742 311.135 272.274C311.135 272.274 312.265 294.357 311.135 298.748C310.005 303.14 310.006 314.181 312.265 318.573C314.524 322.964 319.795 338.146 312.265 343.918C306.491 348.309 284.528 376.039 284.653 379.301C284.653 379.301 287.916 400.255 287.916 402.513C287.916 404.772 284.653 408.034 284.653 408.034C284.653 408.034 276.872 411.296 273.608 410.292C270.345 409.163 263.693 407.03 263.693 404.772C263.693 402.513 262.564 388.209 262.564 384.947C262.564 381.685 260.305 381.685 260.305 376.164C260.305 370.643 279.131 319.827 279.131 319.827L273.608 295.486"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M249.762 326.854L269.717 322.462L278.001 316.314"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M267.96 350.442L258.045 350.568L249.26 348.435"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M254.28 349.69L260.304 353.956L262.564 360.606C262.564 360.606 256.539 360.104 254.28 362.237C252.021 364.37 249.385 367.632 249.385 367.632"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M249.385 374.659L257.543 386.578C257.543 386.578 258.673 387.708 257.543 388.837C256.414 389.966 249.636 392.35 248.758 392.099"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M265.576 407.03C265.576 407.03 268.588 426.227 267.458 428.36C266.329 430.619 255.284 436.14 254.154 436.14C253.025 436.14 249.762 437.269 249.762 437.269"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M249.26 445.55C249.26 445.55 263.819 446.805 264.697 445.55C266.956 442.288 274.612 444.421 274.612 444.421L275.742 445.55L286.786 444.421C286.786 444.421 286.786 438.9 284.527 435.638C282.268 432.376 284.527 426.855 284.527 424.596V408.034"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M253.401 450.945L287.288 447.181C287.288 447.181 290.552 443.919 289.547 441.66C288.543 439.402 286.159 438.147 286.159 438.147"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256.414 281.558C256.414 281.558 260.807 284.821 265.2 283.817C269.592 282.688 289.548 281.558 289.548 281.558"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M226.041 266.753C227.422 267.506 232.066 268.384 234.325 268.384C236.584 268.384 238.717 269.513 239.847 270.642L254.155 284.946C254.155 284.946 256.414 279.425 257.418 276.163C258.548 272.901 261.811 265.122 261.811 265.122C261.811 265.122 246.373 258.472 244.114 257.342C241.855 256.213 238.592 251.822 237.462 251.822C236.333 251.822 231.94 251.822 229.681 250.692C227.422 249.563 225.288 249.563 224.159 248.434C223.029 247.305 220.895 250.692 220.895 250.692L221.397 252.324C221.397 252.324 220.142 252.575 220.017 253.076C219.891 253.578 219.264 254.958 220.393 255.586C221.523 256.213 219.013 256.966 219.264 258.095C219.891 260.354 223.531 261.358 223.531 261.358C223.28 261.358 223.155 261.734 222.402 262.487C221.649 263.365 226.041 266.753 226.041 266.753Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M229.43 249.94L229.806 249.312C230.183 248.685 230.936 248.434 231.563 248.81L234.45 250.442C234.827 250.692 235.078 251.069 235.078 251.571"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M261.559 283.943C261.559 283.943 263.442 288.585 262.563 289.84C260.304 293.102 259.3 293.102 259.3 293.102"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M262.062 285.448L265.325 285.95L267.584 283.566"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M259.175 300.254L220.519 292.098L220.644 286.076L260.43 293.855L259.175 300.254Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M303.981 311.421C299.212 311.421 282.77 306.151 276.872 304.269L276.495 295.109C287.791 298.748 301.471 302.638 303.856 302.638C307.997 302.638 310.131 302.512 310.257 302.512L310.884 311.295C310.884 311.17 308.499 311.421 303.981 311.421Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M271.349 302.638C273.498 302.638 275.24 300.896 275.24 298.748C275.24 296.6 273.498 294.859 271.349 294.859C269.2 294.859 267.458 296.6 267.458 298.748C267.458 300.896 269.2 302.638 271.349 302.638Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M267.458 302.01C269.607 302.01 271.349 300.269 271.349 298.121C271.349 295.973 269.607 294.231 267.458 294.231C265.309 294.231 263.567 295.973 263.567 298.121C263.567 300.269 265.309 302.01 267.458 302.01Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M259.802 296.992L267.458 298.121"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M272.479 282.939L272.981 290.467V295.235"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M224.409 312.55H201.19V318.071H224.409V312.55Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M225.037 337.393H201.818V342.914H225.037V337.393Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.993 366.001H201.818V370.392H213.993V366.001Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M221.648 371.522H209.474V374.784H221.648V371.522Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M223.907 380.43H220.519V374.909"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M220.519 371.522V366.001H223.907"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.49 225.849H201.316V230.241H213.49V225.849Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M221.272 231.37H209.098V234.632H221.272V231.37Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.405 240.153H220.142V234.758"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M220.142 231.37V225.849H223.405"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M213.49 84.568H201.316V88.9595H213.49V84.568Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M221.272 90.0887H209.098V93.351H221.272V90.0887Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M223.405 98.8717H220.142V93.351"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M220.142 90.0887V84.568H223.405"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M219.64 44.1661C218.887 43.5387 217.883 43.0368 216.754 43.0368C214.369 43.0368 212.361 45.0444 212.361 47.4283C212.361 49.8123 214.369 51.8198 216.754 51.8198L223.657 51.6944"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M251.895 47.4283C252.648 46.801 253.652 46.4246 254.782 46.4246C257.167 46.4246 259.175 48.1812 259.175 50.3142C259.175 52.4472 257.167 54.2038 254.782 54.2038L249.385 53.8274"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M164.793 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M166.551 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M168.308 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M170.065 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M171.822 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M173.579 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M175.336 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M177.093 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M178.85 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M180.607 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M182.364 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M184.122 40.9038V46.4246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M285.155 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M286.912 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M288.669 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M290.426 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M292.184 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M293.94 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M295.697 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M297.455 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M299.211 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M300.969 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M302.726 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M304.483 51.9453V57.4661"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M184.624 44.2916L214.494 47.5538"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256.414 50.816L286.285 54.2038"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M304.608 54.8311L1440 74.1435"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M192.907 350.066H179.729V302.512H192.907"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M186.255 302.01C187.384 297.87 189.142 295.36 190.648 295.36C194.287 295.36 197.3 309.413 197.3 326.854C197.3 344.294 194.287 358.347 190.648 358.347C188.891 358.347 187.384 355.336 186.255 350.317"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M188.891 302.01C190.02 297.87 191.401 295.36 192.907 295.36C196.547 295.36 199.559 309.413 199.559 326.854C199.559 344.169 196.547 358.347 192.907 358.347C191.15 358.347 189.644 355.21 188.514 350.192"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M201.567 318.071C201.693 320.831 201.818 323.842 201.818 326.854C201.818 344.169 198.806 358.347 195.166 358.347C193.409 358.347 191.778 354.959 190.522 349.439"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M190.773 303.014C191.903 298.246 193.409 295.36 195.041 295.36C197.676 295.36 199.936 302.387 200.94 312.676"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M203.073 343.165C201.944 352.325 199.81 358.347 197.426 358.347C195.668 358.347 194.037 354.96 192.782 349.564"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M203.701 318.196C203.826 320.957 203.952 323.843 203.952 326.854C203.952 330.493 203.826 334.131 203.575 337.393"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M192.907 303.14C194.037 298.372 195.668 295.36 197.3 295.36C199.81 295.36 202.069 302.261 203.199 312.425"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M184.373 302.387C185.502 297.995 187.008 295.486 188.514 295.486C192.154 295.486 195.166 309.539 195.166 326.979C195.166 344.294 192.154 358.473 188.514 358.473C186.757 358.473 185 354.959 183.871 349.439"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M198.555 357.845C198.555 357.845 200.186 357.72 201.19 358.849L203.951 361.609"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M301.973 236.765L271.349 238.145M267.333 243.039C266.705 243.039 266.203 243.039 266.203 242.286L266.078 240.655C266.078 240.027 266.58 239.776 267.082 239.525L268.839 238.773C269.341 238.647 269.592 238.145 269.592 237.643C270.972 216.69 300.592 217.317 301.471 236.389C301.471 236.514 301.596 236.64 301.847 236.64C302.224 236.64 302.475 237.016 302.475 237.267L302.6 240.655C302.6 241.031 302.349 241.408 301.847 241.408C290.552 242.035 278.879 242.537 267.333 243.039Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M280.26 222.838L280.888 219.952C280.888 219.952 287.791 218.948 291.807 220.705C295.823 222.461 296.325 225.347 296.325 225.347"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M280.26 225.222C280.26 225.222 282.519 224.092 284.276 224.72"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M273.357 243.039L274.11 248.434H275.24L274.11 256.213H277.374L279.633 261.734L282.896 262.863L289.046 258.974"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M300.09 241.282C300.09 241.282 300.341 245.674 300.09 246.803C297.831 255.586 290.175 252.324 290.175 252.324C290.175 252.324 294.568 244.544 290.175 243.541C289.046 243.29 286.912 243.541 285.783 244.67C284.653 245.799 284.653 246.928 284.653 246.928L280.26 242.537"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M301.094 255.46L298.96 250.191"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M261.811 360.355L263.568 362.739"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M263.568 400.255L260.681 400.757"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M164.04 43.6642L0 34.0334"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M251.519 29.9877H221.523V37.767H251.519V29.9877Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M184.247 42.6604C184.247 42.6604 213.867 45.5463 218.636 46.6755C223.405 47.8047 227.673 48.1812 227.673 51.8198C227.673 55.4585 227.673 249.814 227.673 249.814"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M227.296 267.255V286.954"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M227.296 293.729C227.296 293.729 226.92 303.391 225.916 305.022C224.033 308.159 221.648 308.409 218.511 308.786C215.373 309.162 193.66 308.911 193.66 308.911"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M193.158 306.025C193.158 306.025 223.907 306.025 226.669 306.025C230.559 306.025 238.341 306.527 238.341 303.014C238.341 299.501 238.592 296.239 238.592 296.239"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M238.968 289.087L239.47 270.266"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M240.098 253.704C240.098 253.704 238.215 82.5604 238.717 73.5265C239.219 63.9906 238.09 49.3104 248.758 49.3104C259.426 49.3104 284.402 52.9491 284.402 52.9491"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1076.35 389.339H1085.26"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1076.35 379.928H1085.26"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1093.3 377.795C1092.92 377.419 1086.39 375.537 1086.39 375.537C1086.39 375.537 1084.64 373.529 1084.64 373.153C1084.51 372.776 1085.01 371.898 1085.51 371.396C1085.89 370.769 1085.39 369.64 1084.26 367.381C1083 365.123 1079.36 363.993 1078.74 363.993C1078.11 363.868 1077.86 364.621 1077.61 364.997C1077.36 365.374 1076.6 365.374 1076.6 365.374C1076.6 365.374 1075.85 365.499 1075.6 364.997C1075.35 364.621 1075.1 363.868 1074.47 363.993C1073.84 364.119 1070.2 365.248 1068.95 367.381C1067.69 369.64 1067.19 370.769 1067.69 371.396C1068.07 372.024 1068.7 372.776 1068.57 373.153C1068.45 373.529 1066.81 375.537 1066.81 375.537C1066.81 375.537 1060.29 377.419 1059.91 377.795C1059.53 378.172 1059.03 378.423 1059.53 379.301C1059.91 379.928 1067.82 379.803 1067.82 379.803C1067.82 379.803 1067.19 381.559 1066.06 383.191C1065.06 384.571 1061.29 387.457 1061.42 387.959C1061.54 388.46 1061.79 389.339 1062.67 389.213C1063.68 389.088 1068.07 389.213 1068.07 389.213C1068.07 389.213 1065.06 396.491 1064.55 397.369C1064.05 398.373 1061.92 400.757 1062.3 401.133C1062.67 401.509 1063.68 401.76 1064.05 401.76H1076.35H1076.6H1088.9C1089.28 401.76 1090.41 401.509 1090.66 401.133C1091.04 400.757 1088.9 398.247 1088.4 397.369C1087.9 396.365 1084.89 389.213 1084.89 389.213C1084.89 389.213 1089.41 389.088 1090.28 389.213C1091.29 389.339 1091.54 388.46 1091.54 387.959C1091.66 387.457 1087.9 384.571 1086.9 383.191C1085.77 381.685 1085.14 379.803 1085.14 379.803C1085.14 379.803 1093.17 379.928 1093.42 379.301C1094.17 378.423 1093.67 378.172 1093.3 377.795Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1076.73 389.339H1067.82"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1076.73 379.928H1067.82"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M864.371 469.264L887.966 422.463L911.31 469.264"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M911.31 469.264C911.31 480.682 900.768 489.967 887.841 489.967C874.913 489.967 864.371 480.682 864.371 469.264H911.31Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M932.02 469.264L955.489 422.463L978.834 469.264"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M978.833 469.264C978.833 480.682 968.291 489.967 955.363 489.967C942.436 489.967 931.894 480.682 931.894 469.264H978.833Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M887.966 422.463H955.489"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M921.728 437.269V412.802"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M927.501 395.738C936.663 396.24 941.056 392.475 940.554 386.829C940.177 381.559 932.521 378.548 926.748 377.168C920.849 375.662 916.331 372.776 916.331 368.385C916.331 363.993 921.226 360.355 927.25 360.355C931.392 360.355 935.283 362.99 937.165 365.499"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1145.76 343.792L1143.88 345.173C1143.88 348.811 1144.63 352.45 1145.88 355.963L1147.64 356.967L1148.02 358.347C1149.52 363.617 1154.92 370.643 1160.69 373.027C1163.33 373.529 1166.09 373.529 1168.85 373.027C1174.5 370.643 1180.02 363.743 1181.53 358.347L1181.9 356.967L1183.66 355.963C1184.92 352.45 1185.67 348.811 1185.67 345.173L1183.79 343.792"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1174.12 306.026C1180.9 309.539 1185.54 316.942 1185.54 325.599V334.131C1185.54 335.26 1184.54 336.264 1183.28 336.264H1146.76C1145.51 336.264 1144.5 335.386 1144.5 334.131V325.599C1144.5 317.067 1149.15 309.664 1155.92 306.026"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1169.48 312.299L1168.35 311.295V302.01C1168.35 300.881 1167.35 299.877 1166.09 299.877H1163.83C1162.58 299.877 1161.57 300.756 1161.57 302.01V311.295L1160.44 312.299"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1155.8 322.713V307.28V305.775C1155.8 305.398 1156.05 305.022 1156.55 304.771L1161.57 303.014"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1168.35 302.889L1173.37 304.645C1173.75 304.771 1174.12 305.147 1174.12 305.649V307.155V322.588"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1190.06 337.268V336.766V330.994C1190.06 329.865 1189.06 328.861 1187.8 328.861H1185.54"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1139.86 337.268V336.766V330.994C1139.86 329.865 1140.86 328.861 1142.12 328.861H1144.38"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1139.86 336.641C1139.86 336.766 1139.86 337.017 1139.86 337.142C1140.49 342.914 1151.53 345.424 1164.96 345.424C1178.39 345.424 1189.43 342.914 1190.06 337.142C1190.06 337.017 1190.06 336.766 1190.06 336.641"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1151.53 366.503C1140.86 366.503 1128.19 369.263 1123.67 380.43C1118.52 393.228 1113.5 404.144 1109.74 413.806C1107.35 419.828 1109.74 427.733 1118.15 427.733H1126.56"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1216.54 406.403C1217.42 408.661 1220.31 411.673 1221.19 413.806C1223.57 419.828 1221.19 427.733 1212.78 427.733H1204.37"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1179.39 366.503C1190.06 366.503 1202.74 369.263 1207.26 380.43C1210.52 388.46 1213.78 395.863 1216.54 402.513"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1198.97 406.654V433.379H1132.08V388.837H1198.97V403.015"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1162.7 411.045H1168.22"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1091.41 433.505H1440"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1102.96 526.73H1082"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1102.96 518.324H1082"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1102.96 509.917H1082"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1102.96 501.51H1082"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1035.81 526.73H1056.77"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1035.81 518.324H1056.77"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1035.81 509.917H1056.77"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1035.81 501.51H1056.77"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1065.18 488.963H1025.15V539.403H1061.04C1065.68 539.403 1069.32 543.167 1069.32 547.809L1069.2 497.37C1069.2 492.727 1073.09 488.963 1077.73 488.963H1113.5V539.152L1077.61 539.403C1072.96 539.403 1069.32 543.167 1069.32 547.809V568.763"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1092.54 425.851C1092.54 434.634 1085.39 441.786 1076.6 441.786C1067.82 441.786 1060.66 434.634 1060.66 425.851C1060.66 417.068 1067.82 409.916 1076.6 409.916C1085.39 409.916 1092.54 417.068 1092.54 425.851Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1092.54 441.284C1092.54 450.067 1085.39 457.219 1076.6 457.219C1067.82 457.219 1060.66 450.067 1060.66 441.284C1060.66 432.501 1067.82 425.349 1076.6 425.349C1085.39 425.349 1092.54 432.501 1092.54 441.284Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1076.6 409.916V402.89"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1063.17 433.881H961.388"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M130.53 515.689V475.663C130.53 474.032 131.911 472.652 133.542 472.652H135.927C137.559 472.652 138.939 474.032 138.939 475.663V515.689"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M135.927 472.652L138.813 453.078C139.19 450.318 141.073 447.934 143.708 447.056L157.639 442.037"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M111.829 441.911L125.76 446.93C128.396 447.934 130.279 450.192 130.655 452.953L133.667 472.526"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M190.021 434.885L157.765 446.052V440.657C157.765 438.022 159.397 435.763 161.907 434.885L179.603 428.862C181.863 428.109 184.373 428.737 186.004 430.493L190.021 434.885Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M79.4482 434.885L111.704 446.052V440.657C111.704 438.022 110.072 435.763 107.562 434.885L89.8653 428.862C87.6062 428.109 85.0961 428.737 83.4645 430.493L79.4482 434.885Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M793.459 347.682V409.038H723.677V347.682"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M798.479 419.326H718.531V440.531H798.479V419.326Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M801.993 340.53L758.066 321.835L715.394 340.53"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M803.374 341.283H713.762V347.557H803.374V341.283Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M758.317 347.557V409.665"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 356.716H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 352.45H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 361.108H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 365.499H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 369.765H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 374.157H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 378.548H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 382.814H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 387.206H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 391.597H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 395.863H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 400.255H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M757.815 404.646H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M726.062 409.665V418.574"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M791.2 409.665V418.574"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M829.229 399H811.281H810.779"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M817.808 389.841H811.281H810.779"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M817.808 389.841C820.318 389.841 822.452 387.833 822.452 385.198C822.452 382.689 820.444 380.556 817.808 380.556H793.585"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M811.281 399C811.156 399 810.905 399 810.779 399C808.269 398.875 806.261 396.616 806.512 394.107C806.637 391.723 808.52 389.966 810.905 389.841C811.03 389.841 811.281 389.841 811.407 389.841"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M828.853 345.8V439.653"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M829.229 368.259H837.512C841.779 368.259 845.168 371.773 845.168 375.913V421.71C845.168 425.976 841.654 429.364 837.512 429.364H829.103"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M866.254 396.114C864.245 397.996 861.233 397.871 859.476 395.863L851.067 386.704C849.31 384.822 849.436 381.81 851.318 380.054L851.444 379.928C853.326 378.172 856.339 378.297 858.096 380.179L866.505 389.339C868.387 391.346 868.262 394.358 866.254 396.114Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M871.023 381.685L871.901 382.563C873.658 384.571 873.533 387.582 871.65 389.339C870.521 390.342 868.889 390.719 867.508 390.468"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M855.46 378.799L855.209 378.548C853.452 376.541 853.577 373.529 855.46 371.773C857.468 370.016 860.48 370.142 862.237 372.149L865.249 375.411"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M876.922 382.689C875.666 383.818 874.035 384.194 872.529 383.818"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M859.35 370.393C857.593 368.385 857.719 365.374 859.601 363.617C861.61 361.86 864.622 361.986 866.379 363.868L872.403 369.514"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M866.128 363.617C864.371 361.609 864.496 358.598 866.379 356.842C868.387 355.085 871.399 355.21 873.156 357.093L879.181 364.119"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M856.84 392.852L845.168 405.399"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M875.29 359.1L884.703 349.188L890.979 356.465L884.829 362.864"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M845.168 391.346L850.314 385.826"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M864.496 397.494L881.691 406.654L894.241 422.589"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M884.452 362.99C883.072 362.613 881.817 362.613 880.687 363.115C880.687 363.115 875.165 366.377 868.889 371.898C862.614 377.419 861.484 378.423 863.492 380.681C865.501 382.94 866.63 383.191 871.399 381.183C876.169 379.176 881.189 375.913 881.189 375.913"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M884.452 362.99C887.841 363.868 891.355 366.754 892.61 368.134C895.748 371.396 892.736 381.936 892.736 381.936C892.736 381.936 906.918 411.673 925.619 412.426C931.643 412.676 936.538 408.787 936.538 404.395C936.538 400.004 932.019 397.118 926.121 395.612C920.347 394.232 912.315 390.97 912.315 385.951C912.315 380.179 916.582 376.792 926.748 377.293"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M880.938 388.46C880.938 388.46 874.788 381.183 877.424 378.548"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M154.878 404.27H116.598C113.335 404.27 110.825 401.635 110.825 398.498V371.647C110.825 368.385 113.461 365.875 116.598 365.875H154.878C158.141 365.875 160.652 368.51 160.652 371.647V398.498C160.652 401.635 158.016 404.27 154.878 404.27Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M152.494 370.643H118.858C116.724 370.643 114.842 372.4 114.842 374.658V388.209C114.842 390.342 116.599 392.224 118.858 392.224H127.769C129.15 390.844 132.162 389.841 135.676 389.841C139.19 389.841 142.202 390.844 143.583 392.224H152.494C154.628 392.224 156.51 390.468 156.51 388.209V374.658C156.385 372.4 154.628 370.643 152.494 370.643Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M138.186 397.745C138.186 399.125 137.056 400.255 135.676 400.255C134.295 400.255 133.166 399.125 133.166 397.745C133.166 396.365 134.295 395.236 135.676 395.236C137.056 395.236 138.186 396.365 138.186 397.745Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M118.983 379.552L120.615 381.559"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M152.368 379.552L150.988 381.81"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M147.975 377.042L146.971 379.552"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M143.206 375.286L142.704 377.921"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M138.186 374.408V377.042"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M133.166 374.408L133.542 377.042"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M128.146 375.286L129.024 377.795"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M123.376 376.917L124.631 379.301"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M135.299 389.339L131.659 380.932"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M135.676 404.521V423.216"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M115.72 423.843H155.631"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M125.51 430.368H145.716"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M130.906 435.638H140.445"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1208.89 647.183V618.074L1224.95 583.067L1241.27 618.074V647.183"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1289.59 647.183V618.074L1273.27 583.067H1224.95"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1241.27 618.074H1289.46"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1236.37 606.279H1284.07"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1230.35 594.485H1278.17"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1241.52 583.318L1257.96 617.948"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1257.46 583.318L1273.9 617.948"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1293.35 566.379L1288.96 569.642L1287.83 564.246"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1288.96 569.642L1295.99 550.444"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1279.93 561.988L1275.53 565.25L1274.4 559.855"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1275.53 565.25L1282.56 546.053"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1145.51 435.889L1175.75 409.038C1175.75 409.038 1178.14 406.654 1185.29 406.654H1194.83C1194.83 406.654 1197.22 402.137 1204.37 401.886C1222.69 401.258 1222.69 406.654 1221.06 406.654H1194.96"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1242.65 549.817L1254.95 536.642C1254.95 536.642 1255.95 535.639 1258.84 535.639H1262.73C1262.73 535.639 1263.73 533.757 1266.62 533.757H1273.4C1273.4 533.757 1275.53 535.639 1273.4 535.639H1262.73"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1144 432.877C1151.15 448.31 1159.81 465.625 1168.47 480.306C1184.41 506.906 1214.79 555.212 1229.09 555.212C1243.4 555.212 1247.04 555.463 1247.04 555.463C1247.04 555.463 1254.82 571.147 1262.73 583.443"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1137.22 437.394C1163.58 480.306 1199.98 538.776 1205.37 545.676C1214.66 557.596 1226.71 562.364 1233.86 562.364H1247.04C1247.04 562.364 1249.3 572.778 1252.44 583.067"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1035.81 489.214L1144.38 433.63"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1137.6 580.181V427.984H1144.75L1145.51 580.181"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1242.27 582.942V544.422H1247.04V582.942"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1298 420.707H1263.48C1261.98 420.707 1260.85 419.577 1260.85 418.072V384.069C1260.85 382.563 1261.98 381.434 1263.48 381.434H1298C1299.5 381.434 1300.63 382.563 1300.63 384.069V418.072C1300.63 419.577 1299.5 420.707 1298 420.707Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1298 376.666H1263.48C1261.98 376.666 1260.85 375.537 1260.85 374.031V340.028C1260.85 338.523 1261.98 337.393 1263.48 337.393H1298C1299.5 337.393 1300.63 338.523 1300.63 340.028V374.031C1300.63 375.411 1299.5 376.666 1298 376.666Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1303.52 425.6H1257.96C1256.46 425.6 1255.2 424.345 1255.2 422.84V334.257C1255.2 332.751 1256.46 331.496 1257.96 331.496H1303.52C1305.03 331.496 1306.28 332.751 1306.28 334.257V422.84C1306.28 424.471 1305.03 425.6 1303.52 425.6Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1288.46 395.863H1273.02C1272.52 395.863 1272.14 396.114 1272.14 396.491V403.015C1272.14 403.392 1272.52 403.642 1273.02 403.642H1275.16V404.772C1275.16 405.023 1275.53 405.274 1275.91 405.274H1277.92V406.152C1277.92 406.403 1278.17 406.528 1278.42 406.528H1282.94C1283.19 406.528 1283.44 406.403 1283.44 406.152V405.274H1285.45C1285.82 405.274 1286.2 405.023 1286.2 404.772V403.642H1288.33C1288.84 403.642 1289.21 403.392 1289.21 403.015V396.491C1289.34 396.114 1288.96 395.863 1288.46 395.863Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1275.91 398.122V401.133"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1277.79 398.122V401.133"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1279.8 398.122V401.133"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1281.68 398.122V401.133"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1283.69 398.122V401.133"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1285.57 398.122V401.133"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1280.8 370.142C1288.15 370.142 1294.11 364.187 1294.11 356.842C1294.11 349.496 1288.15 343.542 1280.8 343.542C1273.46 343.542 1267.5 349.496 1267.5 356.842C1267.5 364.187 1273.46 370.142 1280.8 370.142Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1274.03 359.978C1275.34 359.978 1276.41 358.911 1276.41 357.594C1276.41 356.278 1275.34 355.21 1274.03 355.21C1272.71 355.21 1271.64 356.278 1271.64 357.594C1271.64 358.911 1272.71 359.978 1274.03 359.978Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1287.33 359.978C1288.65 359.978 1289.71 358.911 1289.71 357.594C1289.71 356.278 1288.65 355.21 1287.33 355.21C1286.01 355.21 1284.95 356.278 1284.95 357.594C1284.95 358.911 1286.01 359.978 1287.33 359.978Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M570.935 611.925H602.562"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1144.5 612.302H1177.89H1178.89"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1165.71 629.366H1177.76H1178.77"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1165.71 646.556H1440"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1165.71 646.556C1160.94 646.556 1157.18 642.666 1157.18 638.024C1157.18 633.256 1161.07 629.492 1165.71 629.492"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1177.89 612.302C1178.14 612.302 1178.52 612.302 1178.89 612.302C1183.66 612.553 1187.18 616.693 1186.92 621.336C1186.67 625.727 1183.16 629.115 1178.77 629.366C1178.39 629.366 1178.14 629.366 1177.76 629.366"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1305.53 503.643L1301.89 514.936H1307.54L1305.28 522.088H1307.91L1314.31 510.168H1307.91L1310.05 503.518L1305.53 503.643Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1308.16 528.361C1316.76 528.361 1323.73 521.396 1323.73 512.803C1323.73 504.21 1316.76 497.244 1308.16 497.244C1299.57 497.244 1292.6 504.21 1292.6 512.803C1292.6 521.396 1299.57 528.361 1308.16 528.361Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1308.16 545.676V536.517"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1308.16 488.963V479.929"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1324.61 541.285L1320.09 533.38"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1296.24 492.226L1291.72 484.321"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1336.65 529.24L1328.75 524.723"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1287.58 500.883L1279.67 496.366"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1341.05 512.803H1331.89"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1284.32 512.803H1275.28"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1336.65 496.366L1328.75 500.883"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1287.58 524.723L1279.67 529.24"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1324.61 484.321L1320.09 492.226"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1296.24 533.38L1291.72 541.285"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.1162 325.097C63.258 329.489 67.5252 326.226 70.5374 324.219C74.5536 321.459 76.3107 320.706 78.5698 323.842C82.9626 329.865 87.8574 326.477 91.1206 324.219C95.1368 321.459 96.8939 320.706 99.153 323.842C103.546 329.865 108.441 326.477 111.704 324.219C115.72 321.459 117.477 320.706 119.736 323.842C124.129 329.865 129.024 326.477 132.287 324.219C136.303 321.459 138.06 320.706 140.319 323.842C142.202 326.352 144.085 327.23 145.967 327.23C148.603 327.23 150.988 325.599 152.87 324.219C156.886 321.459 158.643 320.706 160.903 323.842C165.295 329.865 170.19 326.477 173.453 324.219C177.47 321.459 179.227 320.706 181.486 323.842"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M139.441 284.821C136.554 283.19 135.927 281.809 136.554 280.931C137.056 280.053 138.562 279.802 141.449 281.559C144.336 283.315 153.498 291.471 153.498 291.471C153.498 291.471 142.327 286.577 139.441 284.821Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M136.805 275.536C137.684 278.798 137.182 280.178 136.052 280.429C135.048 280.68 133.919 279.802 133.04 276.54C132.162 273.277 131.534 260.981 131.534 260.981C131.534 260.981 135.927 272.274 136.805 275.536Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M131.534 283.315C133.793 280.806 135.299 280.555 136.052 281.308C136.805 282.06 136.679 283.441 134.42 285.95C132.161 288.459 121.995 295.486 121.995 295.486C121.995 295.486 129.149 285.825 131.534 283.315Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M140.069 323.717L137.684 283.817"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M134.923 285.699L133.793 323.717"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M111.704 368.385C110.324 368.134 108.692 369.012 106.182 370.769C102.919 373.027 98.1493 376.415 93.7566 370.393C91.4974 367.256 89.8658 368.009 85.8496 370.769C83.967 372.149 81.4569 373.78 78.9467 373.78C77.1896 373.78 75.1815 372.902 73.4244 370.393C71.1652 367.256 69.5337 368.009 65.5174 370.769C62.2542 373.027 57.485 376.415 53.0922 370.393C50.8331 367.256 49.2015 368.009 45.1852 370.769C41.9221 373.027 37.1528 376.415 32.76 370.393C30.5009 367.256 28.8693 368.009 24.8531 370.769C21.5899 373.027 16.8206 376.415 12.4279 370.393C10.1687 367.256 8.53714 368.009 4.52091 370.769C3.2152 371.673 1.66835 372.757 0.000976562 373.347"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M59.3673 298.748C54.2215 295.737 53.2175 293.353 54.2215 291.722C55.2256 290.091 57.7357 289.84 62.8815 292.726C68.0273 295.737 84.2177 310.292 84.2177 310.292C84.2177 310.292 64.5131 301.759 59.3673 298.748Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M54.8491 282.186C56.3552 287.958 55.4766 290.342 53.594 290.843C51.7114 291.345 49.8288 289.714 48.1972 283.942C46.6911 278.171 45.5615 256.339 45.5615 256.339C45.5615 256.339 53.343 276.414 54.8491 282.186Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M45.3102 296.113C49.452 291.722 51.8366 291.345 53.2172 292.6C54.5978 293.855 54.3467 296.364 50.205 300.756C46.0632 305.147 28.2412 317.694 28.2412 317.694C28.2412 317.694 41.294 300.505 45.3102 296.113Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M64.262 371.647L55.9785 296.113"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.4604 300.128L48.4482 368.887"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1261.22 312.299H1257.08V329.489H1261.22V312.299Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1304.27 312.299H1300.13V329.489H1304.27V312.299Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1227.46 131.369H1334.4L1305.15 123.088L1287.46 126.35H1272.77L1257.21 123.339L1227.46 131.369Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1256.96 130.992L1248.17 125.974V132.122L1273.02 197.492L1259.34 311.295L1280.8 289.84H1281.31L1302.77 311.295L1289.09 197.492L1313.94 132.122V125.974L1305.03 130.992"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1289.09 197.492L1256.96 152.825V130.992V111.168L1269.88 130.992L1274.9 126.35L1281.05 130.992L1287.46 126.35L1292.35 130.616L1305.03 111.168V130.992V152.825L1272.9 197.492H1289.09Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1265.37 125.221L1250.43 138.646L1256.83 145.045"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1296.74 123.841L1311.43 138.646L1305.03 145.045"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1281.05 185.824H1292.98L1289.21 174.155H1298L1295.61 165.121L1300.26 165.748"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1280.68 185.824H1268.63L1272.4 174.155H1263.74L1265.99 165.121H1261.35"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1290.09 210.918H1271.39L1281.06 197.743L1290.09 210.918Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1291.85 225.222H1269.38L1281.06 211.169L1291.85 225.222Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1294.74 246.175H1266.75L1281.05 225.473L1294.74 246.175Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1297.12 267.757H1264.49L1281.05 246.426L1297.12 267.757Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1300.01 289.338H1261.85L1281.06 267.757L1300.01 289.338Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1229.09 146.3C1228.72 146.3 1228.47 146.175 1228.34 145.798C1228.34 145.673 1228.22 145.547 1228.22 145.547C1228.09 145.296 1228.09 145.171 1228.09 144.92V144.794C1227.46 144.669 1226.96 144.418 1226.96 144.167C1226.96 143.916 1227.46 143.665 1228.22 143.54C1228.47 143.54 1228.84 143.414 1229.22 143.414C1229.6 143.414 1229.85 143.414 1230.22 143.54C1230.98 143.665 1231.48 143.916 1231.48 144.167C1231.48 144.418 1231.1 144.669 1230.35 144.794V144.92C1230.35 145.171 1230.35 145.422 1230.22 145.547C1230.22 145.673 1230.1 145.798 1230.1 145.798C1229.72 146.175 1229.47 146.3 1229.09 146.3Z"
      fill="#0F483E"
    />
    <path
      d="M1229.09 144.92C1228.72 144.92 1228.47 144.794 1228.34 144.418C1228.34 144.292 1228.22 144.167 1228.22 144.167C1228.09 143.916 1228.09 143.791 1228.09 143.54V143.414C1227.46 143.289 1226.96 143.038 1226.96 142.787C1226.96 142.536 1227.46 142.285 1228.22 142.159C1228.47 142.159 1228.84 142.034 1229.22 142.034C1229.6 142.034 1229.85 142.034 1230.22 142.159C1230.98 142.285 1231.48 142.536 1231.48 142.787C1231.48 143.038 1231.1 143.289 1230.35 143.414V143.54C1230.35 143.791 1230.35 144.041 1230.22 144.167C1230.22 144.292 1230.1 144.418 1230.1 144.418C1229.72 144.669 1229.47 144.92 1229.09 144.92Z"
      fill="#0F483E"
    />
    <path
      d="M1229.09 143.414C1228.72 143.414 1228.47 143.289 1228.34 142.912C1228.34 142.787 1228.22 142.661 1228.22 142.661C1228.09 142.41 1228.09 142.285 1228.09 142.034V141.909C1227.46 141.783 1226.96 141.532 1226.96 141.281C1226.96 141.03 1227.46 140.779 1228.22 140.654C1228.47 140.654 1228.84 140.528 1229.22 140.528C1229.6 140.528 1229.85 140.528 1230.22 140.654C1230.98 140.779 1231.48 141.03 1231.48 141.281C1231.48 141.532 1231.1 141.783 1230.35 141.909V142.034C1230.35 142.285 1230.35 142.536 1230.22 142.661C1230.22 142.787 1230.1 142.912 1230.1 142.912C1229.72 143.289 1229.47 143.414 1229.09 143.414Z"
      fill="#0F483E"
    />
    <path
      d="M1229.09 142.034C1228.72 142.034 1228.47 141.908 1228.34 141.532C1228.34 141.407 1228.22 141.281 1228.22 141.281C1228.09 141.03 1228.09 140.905 1228.09 140.654V140.528C1227.46 140.403 1226.96 140.152 1226.96 139.901C1226.96 139.65 1227.46 139.399 1228.22 139.274C1228.47 139.274 1228.84 139.148 1229.22 139.148C1229.6 139.148 1229.85 139.148 1230.22 139.274C1230.98 139.399 1231.48 139.65 1231.48 139.901C1231.48 140.152 1231.1 140.403 1230.35 140.528V140.654C1230.35 140.905 1230.35 141.156 1230.22 141.281C1230.22 141.407 1230.1 141.532 1230.1 141.532C1229.72 141.783 1229.47 142.034 1229.09 142.034Z"
      fill="#0F483E"
    />
    <path
      d="M1229.09 140.403C1228.72 140.403 1228.47 140.277 1228.34 139.901C1228.34 139.775 1228.22 139.65 1228.22 139.65C1228.09 139.399 1228.09 139.274 1228.09 139.023V138.897C1227.46 138.772 1226.96 138.521 1226.96 138.27C1226.96 138.019 1227.46 137.768 1228.22 137.642C1228.47 137.642 1228.84 137.517 1229.22 137.517C1229.6 137.517 1229.85 137.517 1230.22 137.642C1230.98 137.768 1231.48 138.019 1231.48 138.27C1231.48 138.521 1231.1 138.772 1230.35 138.897V139.023C1230.35 139.274 1230.35 139.525 1230.22 139.65C1230.22 139.775 1230.1 139.901 1230.1 139.901C1229.72 140.152 1229.47 140.403 1229.09 140.403Z"
      fill="#0F483E"
    />
    <path
      d="M1229.09 138.897C1228.72 138.897 1228.47 138.772 1228.34 138.395C1228.34 138.27 1228.22 138.144 1228.22 138.144C1228.09 137.893 1228.09 137.768 1228.09 137.517V137.392C1227.46 137.266 1226.96 137.015 1226.96 136.764C1226.96 136.513 1227.46 136.262 1228.22 136.137C1228.47 136.137 1228.84 136.011 1229.22 136.011C1229.6 136.011 1229.85 136.011 1230.22 136.137C1230.98 136.262 1231.48 136.513 1231.48 136.764C1231.48 137.015 1231.1 137.266 1230.35 137.392C1230.35 137.392 1230.35 137.392 1230.35 137.517C1230.35 137.768 1230.35 138.019 1230.22 138.144C1230.22 138.27 1230.1 138.395 1230.1 138.395C1229.72 138.772 1229.47 138.897 1229.09 138.897Z"
      fill="#0F483E"
    />
    <path
      d="M1229.09 137.517C1228.72 137.517 1228.47 137.392 1228.34 137.015C1228.34 136.89 1228.22 136.764 1228.22 136.764C1228.09 136.513 1228.09 136.388 1228.09 136.137V136.011C1227.46 135.886 1226.96 135.635 1226.96 135.384C1226.96 135.133 1227.46 134.882 1228.22 134.757C1228.47 134.757 1228.84 134.631 1229.22 134.631C1229.6 134.631 1229.85 134.631 1230.22 134.757C1230.98 134.882 1231.48 135.133 1231.48 135.384C1231.48 135.635 1231.1 135.886 1230.35 136.011C1230.35 136.011 1230.35 136.011 1230.35 136.137C1230.35 136.388 1230.35 136.639 1230.22 136.764C1230.22 136.89 1230.1 137.015 1230.1 137.015C1229.72 137.266 1229.47 137.517 1229.09 137.517Z"
      fill="#0F483E"
    />
    <path
      d="M1229.09 136.011C1228.72 136.011 1228.47 135.886 1228.34 135.509C1228.34 135.384 1228.22 135.258 1228.22 135.258C1228.09 135.008 1228.09 134.882 1228.09 134.631C1228.09 134.631 1228.09 134.631 1228.09 134.506C1227.46 134.38 1226.96 134.129 1226.96 133.878C1226.96 133.627 1227.46 133.376 1228.22 133.251C1228.47 133.251 1228.84 133.125 1229.22 133.125C1229.6 133.125 1229.85 133.125 1230.22 133.251C1230.98 133.376 1231.48 133.627 1231.48 133.878C1231.48 134.129 1231.1 134.38 1230.35 134.506C1230.35 134.506 1230.35 134.506 1230.35 134.631C1230.35 134.882 1230.35 135.133 1230.22 135.258C1230.22 135.384 1230.1 135.509 1230.1 135.509C1229.72 135.886 1229.47 136.011 1229.09 136.011Z"
      fill="#0F483E"
    />
    <path
      d="M1281.05 146.3C1280.68 146.3 1280.43 146.175 1280.3 145.798C1280.3 145.673 1280.18 145.547 1280.18 145.547C1280.05 145.296 1280.05 145.171 1280.05 144.92V144.794C1279.42 144.669 1278.92 144.418 1278.92 144.167C1278.92 143.916 1279.42 143.665 1280.18 143.54C1280.43 143.54 1280.8 143.414 1281.18 143.414C1281.56 143.414 1281.81 143.414 1282.18 143.54C1282.94 143.665 1283.44 143.916 1283.44 144.167C1283.44 144.418 1283.06 144.669 1282.31 144.794V144.92C1282.31 145.171 1282.31 145.422 1282.18 145.547C1282.18 145.673 1282.06 145.798 1282.06 145.798C1281.68 146.175 1281.43 146.3 1281.05 146.3Z"
      fill="#0F483E"
    />
    <path
      d="M1281.05 144.92C1280.68 144.92 1280.43 144.794 1280.3 144.418C1280.3 144.292 1280.18 144.167 1280.18 144.167C1280.05 143.916 1280.05 143.791 1280.05 143.54V143.414C1279.42 143.289 1278.92 143.038 1278.92 142.787C1278.92 142.536 1279.42 142.285 1280.18 142.159C1280.43 142.159 1280.8 142.034 1281.18 142.034C1281.56 142.034 1281.81 142.034 1282.18 142.159C1282.94 142.285 1283.44 142.536 1283.44 142.787C1283.44 143.038 1283.06 143.289 1282.31 143.414V143.54C1282.31 143.791 1282.31 144.041 1282.18 144.167C1282.18 144.292 1282.06 144.418 1282.06 144.418C1281.68 144.669 1281.43 144.92 1281.05 144.92Z"
      fill="#0F483E"
    />
    <path
      d="M1281.05 143.414C1280.68 143.414 1280.43 143.289 1280.3 142.912C1280.3 142.787 1280.18 142.661 1280.18 142.661C1280.05 142.41 1280.05 142.285 1280.05 142.034V141.909C1279.42 141.783 1278.92 141.532 1278.92 141.281C1278.92 141.03 1279.42 140.779 1280.18 140.654C1280.43 140.654 1280.8 140.528 1281.18 140.528C1281.56 140.528 1281.81 140.528 1282.18 140.654C1282.94 140.779 1283.44 141.03 1283.44 141.281C1283.44 141.532 1283.06 141.783 1282.31 141.909V142.034C1282.31 142.285 1282.31 142.536 1282.18 142.661C1282.18 142.787 1282.06 142.912 1282.06 142.912C1281.68 143.289 1281.43 143.414 1281.05 143.414Z"
      fill="#0F483E"
    />
    <path
      d="M1281.05 142.034C1280.68 142.034 1280.43 141.908 1280.3 141.532C1280.3 141.407 1280.18 141.281 1280.18 141.281C1280.05 141.03 1280.05 140.905 1280.05 140.654V140.528C1279.42 140.403 1278.92 140.152 1278.92 139.901C1278.92 139.65 1279.42 139.399 1280.18 139.274C1280.43 139.274 1280.8 139.148 1281.18 139.148C1281.56 139.148 1281.81 139.148 1282.18 139.274C1282.94 139.399 1283.44 139.65 1283.44 139.901C1283.44 140.152 1283.06 140.403 1282.31 140.528V140.654C1282.31 140.905 1282.31 141.156 1282.18 141.281C1282.18 141.407 1282.06 141.532 1282.06 141.532C1281.68 141.783 1281.43 142.034 1281.05 142.034Z"
      fill="#0F483E"
    />
    <path
      d="M1281.05 140.403C1280.68 140.403 1280.43 140.277 1280.3 139.901C1280.3 139.775 1280.18 139.65 1280.18 139.65C1280.05 139.399 1280.05 139.274 1280.05 139.023V138.897C1279.42 138.772 1278.92 138.521 1278.92 138.27C1278.92 138.019 1279.42 137.768 1280.18 137.642C1280.43 137.642 1280.8 137.517 1281.18 137.517C1281.56 137.517 1281.81 137.517 1282.18 137.642C1282.94 137.768 1283.44 138.019 1283.44 138.27C1283.44 138.521 1283.06 138.772 1282.31 138.897V139.023C1282.31 139.274 1282.31 139.525 1282.18 139.65C1282.18 139.775 1282.06 139.901 1282.06 139.901C1281.68 140.152 1281.43 140.403 1281.05 140.403Z"
      fill="#0F483E"
    />
    <path
      d="M1281.05 138.897C1280.68 138.897 1280.43 138.772 1280.3 138.395C1280.3 138.27 1280.18 138.144 1280.18 138.144C1280.05 137.893 1280.05 137.768 1280.05 137.517V137.392C1279.42 137.266 1278.92 137.015 1278.92 136.764C1278.92 136.513 1279.42 136.262 1280.18 136.137C1280.43 136.137 1280.8 136.011 1281.18 136.011C1281.56 136.011 1281.81 136.011 1282.18 136.137C1282.94 136.262 1283.44 136.513 1283.44 136.764C1283.44 137.015 1283.06 137.266 1282.31 137.392C1282.31 137.392 1282.31 137.392 1282.31 137.517C1282.31 137.768 1282.31 138.019 1282.18 138.144C1282.18 138.27 1282.06 138.395 1282.06 138.395C1281.68 138.772 1281.43 138.897 1281.05 138.897Z"
      fill="#0F483E"
    />
    <path
      d="M1281.05 137.517C1280.68 137.517 1280.43 137.392 1280.3 137.015C1280.3 136.89 1280.18 136.764 1280.18 136.764C1280.05 136.513 1280.05 136.388 1280.05 136.137V136.011C1279.42 135.886 1278.92 135.635 1278.92 135.384C1278.92 135.133 1279.42 134.882 1280.18 134.757C1280.43 134.757 1280.8 134.631 1281.18 134.631C1281.56 134.631 1281.81 134.631 1282.18 134.757C1282.94 134.882 1283.44 135.133 1283.44 135.384C1283.44 135.635 1283.06 135.886 1282.31 136.011C1282.31 136.011 1282.31 136.011 1282.31 136.137C1282.31 136.388 1282.31 136.639 1282.18 136.764C1282.18 136.89 1282.06 137.015 1282.06 137.015C1281.68 137.266 1281.43 137.517 1281.05 137.517Z"
      fill="#0F483E"
    />
    <path
      d="M1281.05 136.011C1280.68 136.011 1280.43 135.886 1280.3 135.509C1280.3 135.384 1280.18 135.258 1280.18 135.258C1280.05 135.008 1280.05 134.882 1280.05 134.631C1280.05 134.631 1280.05 134.631 1280.05 134.506C1279.42 134.38 1278.92 134.129 1278.92 133.878C1278.92 133.627 1279.42 133.376 1280.18 133.251C1280.43 133.251 1280.8 133.125 1281.18 133.125C1281.56 133.125 1281.81 133.125 1282.18 133.251C1282.94 133.376 1283.44 133.627 1283.44 133.878C1283.44 134.129 1283.06 134.38 1282.31 134.506C1282.31 134.506 1282.31 134.506 1282.31 134.631C1282.31 134.882 1282.31 135.133 1282.18 135.258C1282.18 135.384 1282.06 135.509 1282.06 135.509C1281.68 135.886 1281.43 136.011 1281.05 136.011Z"
      fill="#0F483E"
    />
    <path
      d="M1333.14 146.3C1332.76 146.3 1332.51 146.175 1332.39 145.798C1332.39 145.673 1332.26 145.547 1332.26 145.547C1332.14 145.296 1332.14 145.171 1332.14 144.92V144.794C1331.51 144.669 1331.01 144.418 1331.01 144.167C1331.01 143.916 1331.51 143.665 1332.26 143.54C1332.51 143.54 1332.89 143.414 1333.27 143.414C1333.64 143.414 1333.89 143.414 1334.27 143.54C1335.02 143.665 1335.53 143.916 1335.53 144.167C1335.53 144.418 1335.15 144.669 1334.4 144.794V144.92C1334.4 145.171 1334.4 145.422 1334.27 145.547C1334.27 145.673 1334.14 145.798 1334.14 145.798C1333.77 146.175 1333.39 146.3 1333.14 146.3Z"
      fill="#0F483E"
    />
    <path
      d="M1333.14 144.92C1332.76 144.92 1332.51 144.794 1332.39 144.418C1332.39 144.292 1332.26 144.167 1332.26 144.167C1332.14 143.916 1332.14 143.791 1332.14 143.54V143.414C1331.51 143.289 1331.01 143.038 1331.01 142.787C1331.01 142.536 1331.51 142.285 1332.26 142.159C1332.51 142.159 1332.89 142.034 1333.27 142.034C1333.64 142.034 1333.89 142.034 1334.27 142.159C1335.02 142.285 1335.53 142.536 1335.53 142.787C1335.53 143.038 1335.15 143.289 1334.4 143.414V143.54C1334.4 143.791 1334.4 144.041 1334.27 144.167C1334.27 144.292 1334.14 144.418 1334.14 144.418C1333.77 144.669 1333.39 144.92 1333.14 144.92Z"
      fill="#0F483E"
    />
    <path
      d="M1333.14 143.414C1332.76 143.414 1332.51 143.289 1332.39 142.912C1332.39 142.787 1332.26 142.661 1332.26 142.661C1332.14 142.41 1332.14 142.285 1332.14 142.034V141.909C1331.51 141.783 1331.01 141.532 1331.01 141.281C1331.01 141.03 1331.51 140.779 1332.26 140.654C1332.51 140.654 1332.89 140.528 1333.27 140.528C1333.64 140.528 1333.89 140.528 1334.27 140.654C1335.02 140.779 1335.53 141.03 1335.53 141.281C1335.53 141.532 1335.15 141.783 1334.4 141.909V142.034C1334.4 142.285 1334.4 142.536 1334.27 142.661C1334.27 142.787 1334.14 142.912 1334.14 142.912C1333.77 143.289 1333.39 143.414 1333.14 143.414Z"
      fill="#0F483E"
    />
    <path
      d="M1333.14 142.034C1332.76 142.034 1332.51 141.908 1332.39 141.532C1332.39 141.407 1332.26 141.281 1332.26 141.281C1332.14 141.03 1332.14 140.905 1332.14 140.654V140.528C1331.51 140.403 1331.01 140.152 1331.01 139.901C1331.01 139.65 1331.51 139.399 1332.26 139.274C1332.51 139.274 1332.89 139.148 1333.27 139.148C1333.64 139.148 1333.89 139.148 1334.27 139.274C1335.02 139.399 1335.53 139.65 1335.53 139.901C1335.53 140.152 1335.15 140.403 1334.4 140.528V140.654C1334.4 140.905 1334.4 141.156 1334.27 141.281C1334.27 141.407 1334.14 141.532 1334.14 141.532C1333.77 141.783 1333.39 142.034 1333.14 142.034Z"
      fill="#0F483E"
    />
    <path
      d="M1333.14 140.403C1332.76 140.403 1332.51 140.277 1332.39 139.901C1332.39 139.775 1332.26 139.65 1332.26 139.65C1332.14 139.399 1332.14 139.274 1332.14 139.023V138.897C1331.51 138.772 1331.01 138.521 1331.01 138.27C1331.01 138.019 1331.51 137.768 1332.26 137.642C1332.51 137.642 1332.89 137.517 1333.27 137.517C1333.64 137.517 1333.89 137.517 1334.27 137.642C1335.02 137.768 1335.53 138.019 1335.53 138.27C1335.53 138.521 1335.15 138.772 1334.4 138.897V139.023C1334.4 139.274 1334.4 139.525 1334.27 139.65C1334.27 139.775 1334.14 139.901 1334.14 139.901C1333.77 140.152 1333.39 140.403 1333.14 140.403Z"
      fill="#0F483E"
    />
    <path
      d="M1333.14 138.897C1332.76 138.897 1332.51 138.772 1332.39 138.395C1332.39 138.27 1332.26 138.144 1332.26 138.144C1332.14 137.893 1332.14 137.768 1332.14 137.517V137.392C1331.51 137.266 1331.01 137.015 1331.01 136.764C1331.01 136.513 1331.51 136.262 1332.26 136.137C1332.51 136.137 1332.89 136.011 1333.27 136.011C1333.64 136.011 1333.89 136.011 1334.27 136.137C1335.02 136.262 1335.53 136.513 1335.53 136.764C1335.53 137.015 1335.15 137.266 1334.4 137.392C1334.4 137.392 1334.4 137.392 1334.4 137.517C1334.4 137.768 1334.4 138.019 1334.27 138.144C1334.27 138.27 1334.14 138.395 1334.14 138.395C1333.77 138.772 1333.39 138.897 1333.14 138.897Z"
      fill="#0F483E"
    />
    <path
      d="M1333.14 137.517C1332.76 137.517 1332.51 137.392 1332.39 137.015C1332.39 136.89 1332.26 136.764 1332.26 136.764C1332.14 136.513 1332.14 136.388 1332.14 136.137V136.011C1331.51 135.886 1331.01 135.635 1331.01 135.384C1331.01 135.133 1331.51 134.882 1332.26 134.757C1332.51 134.757 1332.89 134.631 1333.27 134.631C1333.64 134.631 1333.89 134.631 1334.27 134.757C1335.02 134.882 1335.53 135.133 1335.53 135.384C1335.53 135.635 1335.15 135.886 1334.4 136.011C1334.4 136.011 1334.4 136.011 1334.4 136.137C1334.4 136.388 1334.4 136.639 1334.27 136.764C1334.27 136.89 1334.14 137.015 1334.14 137.015C1333.77 137.266 1333.39 137.517 1333.14 137.517Z"
      fill="#0F483E"
    />
    <path
      d="M1333.14 136.011C1332.76 136.011 1332.51 135.886 1332.39 135.509C1332.39 135.384 1332.26 135.258 1332.26 135.258C1332.14 135.008 1332.14 134.882 1332.14 134.631C1332.14 134.631 1332.14 134.631 1332.14 134.506C1331.51 134.38 1331.01 134.129 1331.01 133.878C1331.01 133.627 1331.51 133.376 1332.26 133.251C1332.51 133.251 1332.89 133.125 1333.27 133.125C1333.64 133.125 1333.89 133.125 1334.27 133.251C1335.02 133.376 1335.53 133.627 1335.53 133.878C1335.53 134.129 1335.15 134.38 1334.4 134.506C1334.4 134.506 1334.4 134.506 1334.4 134.631C1334.4 134.882 1334.4 135.133 1334.27 135.258C1334.27 135.384 1334.14 135.509 1334.14 135.509C1333.77 135.886 1333.39 136.011 1333.14 136.011Z"
      fill="#0F483E"
    />
    <path
      d="M1229.09 131.243V134.129"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1281.05 131.243V134.129"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1333.02 131.243V134.129"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M835.253 537.772L835.755 536.015H845.796V539.905H835.755L835.253 537.772ZM835.253 537.772H809.147C807.139 537.772 805.131 537.395 803.876 535.764C802.621 534.258 802.496 532.376 802.496 530.494V511.925C802.496 510.168 802.621 508.286 803.876 506.78C805.131 505.149 807.265 504.773 809.147 504.773L835.253 537.772ZM853.702 528.863H845.67M846.8 510.168C842.407 514.559 835.253 514.559 830.86 510.168C826.467 505.776 826.467 498.625 830.86 494.233C835.253 489.842 842.407 489.842 846.8 494.233C851.192 498.625 851.192 505.776 846.8 510.168ZM853.702 537.897H845.67H853.702ZM811.783 504.898H818.937H811.783ZM835.253 528.738H815.925C813.164 528.738 811.532 528.11 810.528 526.605C809.399 524.974 809.398 522.464 809.398 520.582V502.765C809.273 499.001 810.277 496.492 814.544 496.617H819.063L835.253 528.738ZM845.168 506.78L843.411 508.537C842.909 509.039 842.03 509.039 841.528 508.537L832.492 499.503C831.99 499.001 831.99 498.123 832.492 497.621L834.249 495.864C834.751 495.362 835.629 495.362 836.131 495.864L845.168 504.898C845.796 505.4 845.796 506.278 845.168 506.78ZM835.253 528.738L835.755 526.981H845.796V530.871H835.755L835.253 528.738Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M148.352 521.209V590.846H120.74V521.209"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M118.481 521.209V628.111H121.368V590.846"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M150.736 521.209V628.111H147.724V590.846"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M145.59 527.985H123.501V532.627H145.59V527.985Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M145.59 540.281H123.501V544.924H145.59V540.281Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M145.59 579.805H123.501V584.447H145.59V579.805Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M151.49 516.316H117.603V520.708H151.49V516.316Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M201.19 520.708H157.012V516.316H202.696"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M135.174 518.575C135.174 518.951 134.923 519.202 134.546 519.202C134.169 519.202 133.918 518.951 133.918 518.575C133.918 518.198 134.169 517.947 134.546 517.947C134.923 517.947 135.174 518.198 135.174 518.575Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M177.093 518.575C177.093 518.951 176.842 519.202 176.465 519.202C176.089 519.202 175.838 518.951 175.838 518.575C175.838 518.198 176.089 517.947 176.465 517.947C176.842 517.947 177.093 518.198 177.093 518.575Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M160.777 520.959H158.016V628.362H160.777V520.959Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M221.648 532.627V628.362H218.887V531.498"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M160.902 591.474H219.012"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M160.902 627.609H219.012"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M121.368 627.609H147.725"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M124.129 624.975C124.129 625.351 123.878 625.602 123.502 625.602C123.125 625.602 122.874 625.351 122.874 624.975C122.874 624.598 123.125 624.347 123.502 624.347C123.878 624.347 124.129 624.598 124.129 624.975Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M163.915 624.975C163.915 625.351 163.664 625.602 163.287 625.602C162.911 625.602 162.66 625.351 162.66 624.975C162.66 624.598 162.911 624.347 163.287 624.347C163.664 624.347 163.915 624.598 163.915 624.975Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M175.838 527.232H165.546V564.999H175.838V527.232Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M173.704 533.506H171.821V531.624H169.562V533.506H167.554V537.646H169.562V543.042H171.821V537.646H173.704V533.506Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M174.081 549.19H167.178V562.364H174.081V549.19Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M171.822 554.209C171.822 554.836 171.32 555.338 170.692 555.338C170.065 555.338 169.562 554.836 169.562 554.209C169.562 553.581 170.065 553.079 170.692 553.079C171.32 552.954 171.822 553.456 171.822 554.209Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M138.437 554.961H130.906V566.505H138.437V554.961Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M133.918 557.847L132.914 561.36H134.546L133.918 563.493H134.671L136.679 559.855H134.671L135.424 557.847H133.918Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1014.85 628.99C1009.96 628.99 1008.45 625.602 1007.32 623.218C1007.2 622.967 1007.07 622.716 1007.07 622.591C1006.7 621.838 1006.07 620.207 1005.19 618.074C1004.31 615.815 1007.07 612.804 1007.2 612.678L1007.32 612.553L1023.89 606.781C1025.65 606.154 1026.9 604.648 1027.03 602.766L1027.28 600.382L1028.91 599.755L1029.91 603.143C1030.42 604.774 1030.54 606.405 1030.42 607.91C1029.91 613.18 1027.91 625.602 1020.25 628.111C1018.12 628.613 1016.36 628.99 1014.85 628.99Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1005.69 559.98L1005.82 556.969C1005.57 556.342 1005.06 555.965 1004.44 555.84C1003.81 555.714 1003.18 555.84 1002.68 556.342L994.899 563.368C993.518 564.623 992.765 566.505 993.016 568.387L999.417 612.804C999.542 613.682 1000.04 614.56 1000.92 615.062C1001.43 615.439 1002.18 615.69 1002.81 615.69C1004.31 615.69 1005.69 614.686 1006.07 613.306C1006.07 613.055 1006.19 612.929 1006.19 612.678L1010.09 573.908"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1051.25 576.793L1046.61 574.033C1044.6 572.778 1042.21 572.151 1039.83 572.276C1033.68 572.527 1019.37 573.28 1010.59 573.657C1006.32 573.908 1003.43 574.033 1003.43 574.033C1001.55 574.159 999.919 572.778 999.542 570.896C999.166 568.889 1000.42 567.007 1002.43 566.505L1043.09 557.973C1046.86 557.22 1050.75 558.224 1053.76 560.733C1057.15 563.493 1065.56 570.645 1065.56 570.645L1067.32 571.9"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1046.36 642.541C1040.08 642.541 1034.94 637.396 1034.94 631.123V630.997C1034.94 625.1 1039.33 620.207 1045.23 619.705L1069.2 617.321C1072.34 617.07 1075.35 616.819 1078.49 616.819L1104.09 616.944C1107.1 616.944 1110.11 617.07 1113.13 617.446L1135.22 619.705C1140.99 620.332 1145.38 625.1 1145.38 630.997V631.248C1145.38 637.522 1140.24 642.666 1133.96 642.666H1070.2"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1078.49 619.579C1075.47 619.579 1072.46 619.705 1069.45 620.081L1045.48 622.465C1041.08 622.967 1037.7 626.606 1037.7 631.123V631.248C1037.7 636.016 1041.59 639.906 1046.36 639.906H1134.09C1138.86 639.906 1142.75 636.016 1142.75 631.248V630.997C1142.75 626.48 1139.36 622.842 1134.96 622.34L1112.88 620.081C1109.99 619.83 1107.1 619.579 1104.09 619.579H1078.49Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1066.81 572.025C1068.32 569.767 1070.96 568.387 1073.72 568.387H1102.21C1103.59 568.387 1104.72 569.265 1104.97 570.645L1108.48 585.075V615.188H1064.68C1061.79 615.188 1059.41 612.804 1059.41 609.918V609.792C1059.41 600.884 1060.66 591.975 1063.17 583.443L1065.81 574.535C1065.94 573.531 1066.31 572.778 1066.81 572.025Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1074.6 573.28C1072.46 573.28 1070.58 574.66 1070.08 576.668L1067.57 585.325C1065.81 591.223 1064.81 597.245 1064.3 603.268C1064.3 603.644 1064.43 604.146 1064.68 604.397C1065.06 604.774 1065.56 605.025 1066.06 604.899C1068.7 604.146 1070.33 603.393 1071.96 602.641C1074.6 601.511 1076.85 600.508 1082.75 599.629C1084.64 599.378 1086.02 597.747 1086.14 595.74L1087.27 575.539C1087.27 574.911 1087.15 574.409 1086.64 573.907C1086.27 573.406 1085.64 573.28 1085.01 573.28H1074.6V573.28Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1096.18 574.786C1095.81 574.786 1095.43 575.162 1095.43 575.539L1094.93 593.105C1094.93 593.481 1095.05 593.858 1095.3 594.109C1095.56 594.485 1096.06 594.61 1096.56 594.485L1103.09 592.854C1103.71 592.728 1104.22 592.226 1104.22 591.474V587.584C1104.22 586.329 1104.09 585.2 1103.71 583.945L1101.45 575.288C1101.33 574.911 1101.08 574.66 1100.7 574.66H1096.18V574.786Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1003.68 558.976C1003.06 558.976 1002.43 559.353 1002.18 559.98C1002.05 560.357 1002.05 560.859 1002.18 561.235C1002.3 561.611 1002.68 561.988 1003.06 562.113C1003.81 562.49 1004.81 562.113 1005.19 561.235C1005.57 560.482 1005.19 559.478 1004.31 559.102C1004.06 558.976 1003.93 558.976 1003.68 558.976Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1046.23 561.988C1045.1 561.988 1044.22 562.615 1043.72 563.619C1043.47 564.246 1043.47 564.999 1043.72 565.752C1043.97 566.379 1044.47 567.007 1045.23 567.258C1046.61 567.885 1048.24 567.132 1048.74 565.877C1049.37 564.497 1048.74 562.866 1047.36 562.364C1046.98 561.988 1046.61 561.988 1046.23 561.988Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1002.81 610.922C1002.18 610.922 1001.68 611.424 1001.68 612.051C1001.68 612.678 1002.18 613.18 1002.81 613.18C1003.43 613.18 1003.94 612.678 1003.94 612.051C1003.94 611.424 1003.43 610.922 1002.81 610.922Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M995.4 569.391L1001.3 608.914"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1005.19 559.98H1019.62"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1004.31 561.862H1019.62"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1004.69 561.862L1008.83 565.375"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1020 562.992V558.475L1042.34 557.973"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1051.75 575.413L1046.36 567.508"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1048.74 565.877L1054.14 573.657"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1061.17 592.603L1051 576.166L1055.27 572.778L1064.05 580.934"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1091.41 568.387V614.937"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1108.23 584.949H1120.15L1124.17 580.809H1144.88C1144.88 580.809 1149.77 582.189 1149.77 586.831V605.526C1149.77 605.526 1148.77 612.929 1141.87 612.929H1108.23"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1145.38 587.709H1141.37V591.725H1145.38V587.709Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1138.48 587.709H1134.46V591.725H1138.48V587.709Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1108.73 596.618H1149.27"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1128.56 584.698H1149.77"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1054.26 638.149C1058.35 638.149 1061.67 634.835 1061.67 630.746C1061.67 626.658 1058.35 623.343 1054.26 623.343C1050.17 623.343 1046.86 626.658 1046.86 630.746C1046.86 634.835 1050.17 638.149 1054.26 638.149Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1122.04 638.149C1126.13 638.149 1129.44 634.835 1129.44 630.746C1129.44 626.658 1126.13 623.343 1122.04 623.343C1117.95 623.343 1114.63 626.658 1114.63 630.746C1114.63 634.835 1117.95 638.149 1122.04 638.149Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1134.96 629.241C1136.35 629.241 1137.47 628.117 1137.47 626.731C1137.47 625.345 1136.35 624.222 1134.96 624.222C1133.58 624.222 1132.45 625.345 1132.45 626.731C1132.45 628.117 1133.58 629.241 1134.96 629.241Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1041.84 633.256C1043.22 633.256 1044.35 632.132 1044.35 630.746C1044.35 629.36 1043.22 628.237 1041.84 628.237C1040.45 628.237 1039.33 629.36 1039.33 630.746C1039.33 632.132 1040.45 633.256 1041.84 633.256Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1134.96 638.149C1136.35 638.149 1137.47 637.026 1137.47 635.64C1137.47 634.254 1136.35 633.13 1134.96 633.13C1133.58 633.13 1132.45 634.254 1132.45 635.64C1132.45 637.026 1133.58 638.149 1134.96 638.149Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1058.28 624.222H1118.65"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1059.16 636.142H1116.64"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1135.47 603.519V609.416"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1138.1 603.519V609.416"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1143.37 603.519V609.416"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1140.74 603.519V609.416"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1004.69 617.321L1030.54 608.412"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1012.72 610.42V614.435"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1017.62 608.412V612.427"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1022.51 607.409V611.424"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M967.412 642.415C969.214 642.415 970.675 640.955 970.675 639.153C970.675 637.351 969.214 635.891 967.412 635.891C965.61 635.891 964.149 637.351 964.149 639.153C964.149 640.955 965.61 642.415 967.412 642.415Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M967.914 642.29H986.615C988.372 642.29 989.752 640.784 989.752 639.027C989.752 637.271 988.372 635.891 986.615 635.765H967.914"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M956.493 642.415C958.295 642.415 959.756 640.955 959.756 639.153C959.756 637.351 958.295 635.891 956.493 635.891C954.691 635.891 953.23 637.351 953.23 639.153C953.23 640.955 954.691 642.415 956.493 642.415Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M967.036 635.891H956.996"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M956.996 642.29H967.413"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M987.87 642.039C987.87 642.039 991.384 642.039 994.522 644.548C997.66 647.058 997.66 653.959 1002.68 657.723C1007.7 661.487 1012.09 666.506 1018.37 663.369C1024.64 660.232 1024.39 656.217 1025.65 649.943C1026.9 643.67 1035.06 642.039 1037.57 642.039C1040.08 642.039 1046.48 643.042 1048.49 642.039C1053.01 639.78 1052.88 642.039 1054.14 641.411C1055.39 640.784 1064.18 639.529 1068.57 641.411C1069.32 641.788 1070.83 642.792 1071.33 642.917"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1010.71 611.424C1010.71 611.424 1012.72 603.519 1015.61 605.526C1018.62 607.534 1018.12 600.131 1020.13 599.127C1022.13 598.124 1027.03 603.142 1027.03 603.142"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1086.14 585.2C1084.13 584.824 1082 584.573 1079.99 584.196C1079.87 584.196 1079.74 584.196 1079.74 583.945V583.569C1079.74 583.443 1079.87 583.443 1079.99 583.318L1080.49 583.192C1080.62 583.192 1080.75 583.067 1080.75 582.942C1081.62 580.056 1084.76 579.554 1086.77 580.683M1080.75 584.824L1079.87 586.078C1079.87 586.204 1079.87 586.455 1079.87 586.58C1079.87 586.58 1079.87 586.58 1079.99 586.58H1080.75V588.211C1080.75 588.588 1081 588.839 1081.5 588.839H1083.63C1083.88 588.839 1084.13 588.462 1084.26 588.086L1080.75 584.824Z"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1082 589.592C1082 589.592 1079.99 592.603 1078.99 592.603C1077.98 592.603 1073.09 593.607 1072.09 594.61L1069.07 597.622C1069.07 597.622 1073.97 596.618 1075.98 596.618C1077.98 596.618 1078.99 597.622 1078.99 597.622L1078.86 600.382"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M1066.19 604.523L1071.08 597.496"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M627.664 635.514L854.832 634.887"
      stroke="#0F483E"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
export default FrontpageDesktop;
