const ChevronRight = () => (
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 1.63333L20.3667 7L15 12.3667L16.6333 14L23.6333 7L16.6333 4.76837e-07L15 1.63333Z"
      fill="currentColor"
    />
  </svg>
);
export default ChevronRight;
