import ChevronRight from "../icons/chevron-right.icon";
import RenLink from "./RenLink";

const LinkArrow = ({ title, url }) => {
  return (
    <div className="u-padding-top c-link-arrow">
      <RenLink href={url} classes="c-link u-roboto500">
        {title}
        <span className="u-vertical-align__middle">
          <ChevronRight />
        </span>
      </RenLink>
    </div>
  );
};

export default LinkArrow;
