import { CardListLink } from "components/card/card-list-link";
import CardListMinor from "components/card/card.list.minor";
import FrontpageDesktop from "components/illustrations/FrontpageDesktop.js";
import FrontpageMobile from "components/illustrations/FrontpageMobile.js";
import FrontpageTablet from "components/illustrations/FrontpageTablet.js";
import Footer from "components/layout/footer";
import Menu from "components/layout/menu";
import PageMeta from "components/layout/pageMeta";
import PortableText from "components/portableText/portableText";
import ArticleMosaic from "components/shared/article.mosaic";
import LinkArrow from "components/shared/link.arrow";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { removeCookie } from "../helpers/cookies";
import { globalQueries } from "helpers/sanityQueries";
import { client } from "helpers/sanity";

const Home = props => {
  const {
    settings,
    doc,
    doc: { lede, menuPointers, articles, events, portableText },
    menu,
    contact,
  } = props;
  const router = useRouter();
  useEffect(() => {
    //eslint-disable-next-line
    if (router.query.hasOwnProperty("logout")) {
      const loginCookies = ["ren-username", "cas-login-stamp"];
      loginCookies.forEach(removeCookie);
      router.replace("/", undefined, {
        shallow: true,
      });
    }
  }, [router, router.query.logout]);

  return (
    <>
      <PageMeta doc={doc} settings={settings} />
      {menu && <Menu menu={menu} />}
      <article id="hovedinnhold">
        <h1 className="visuallyhidden">REN</h1>
        <section className="o-wrapper c-frontpage-hero">
          {lede && (
            <p className="c-frontpage-hero__lede u-fontLead u-text--darkGreen u-space-on-ie">
              {lede}
            </p>
          )}

          <FrontpageMobile className="c-frontpage-hero__image c-frontpage-hero__image--mobile" />

          <FrontpageTablet className="c-frontpage-hero__image c-frontpage-hero__image--tablet" />

          <FrontpageDesktop className="c-frontpage-hero__image c-frontpage-hero__image--desktop u-hide-on-ie" />
        </section>

        {portableText && (
          <section className="u-padding-top-bottom--large">
            <div className="o-wrapper">
              <PortableText blocks={portableText} />
            </div>
          </section>
        )}

        {events.eventsList && (
          <section className="u-bg--light-yellow u-margin-top">
            <div className="o-wrapper c-document-listing--padding-large">
              <h2
                className="u-padding-bottom c-document-listing__title"
                id="arrangement"
              >
                Kurs og arrangementer
              </h2>
              <CardListMinor entries={events.eventsList} index={0} />
              <LinkArrow title="Se full kursoversikt" url="/arrangement" />
            </div>
          </section>
        )}

        {menuPointers.menuPointList && (
          <section className="u-bg--lightgrey">
            <div className="o-wrapper c-document-listing--padding-large">
              <h2
                className="u-padding-bottom c-document-listing__title"
                id="fagomrader"
              >
                Våre virksomhetsområder
              </h2>
              <CardListLink entries={menuPointers.menuPointList} />
              <LinkArrow title="Se fagområder" url="/fagomrader" />
            </div>
          </section>
        )}

        {articles.articlesList && (
          <section className="u-bg--lightgrey u-padding-top-bottom--larger">
            {articles && (
              <div className="o-wrapper">
                <h2
                  className="u-padding-bottom c-document-listing__title"
                  id="aktuelt"
                >
                  Aktuelt
                </h2>

                <ArticleMosaic entries={articles.articlesList} />
                <LinkArrow title="Se flere aktuelle saker" url="/aktuelt" />
              </div>
            )}
          </section>
        )}
      </article>
      {contact && <Footer contact={contact} settings={settings} />}
    </>
  );
};

export async function getStaticProps({ params }) {
  const groqQuery = {
    doc: `*[ _type=="frontPage" && _id match "*frontPage" && !(_id in path("drafts.*"))][0]
    {
      title,
      lede,
      menuPointers{menuPointList[]->{_id, _type, slug, title, description, frontPageIllustrations}},
      articles{ articlesList[]->{_id, _type, slug, lede, title, image}},
      events{eventsList[]->{_id, _type, slug, title, courseLocation, courseDate, endDate, eventType }},
      portableText
    }
    `,
    ...globalQueries,
  };
  const result = await client.fetch(groqQuery);

  return { props: { ...result, groqQuery } };
}

export default Home;
